import { Component, OnInit } from '@angular/core';
import { GlobalServices } from '../_services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import {Impostazioni, Pazienti_certificato, Lettere} from '../_models';

@Component({
  selector: 'app-matrici',
  templateUrl: './matrici.component.html',
  styleUrls: ['./matrici.component.scss']
})

export class MatriciComponent implements OnInit {

  cdcp= new Impostazioni;
  cs= new Impostazioni;
  m2= new Impostazioni;
  acl=this.service.get_access_level();
  responsabile;
  paziente= new Pazienti_certificato;
  lettere=new Lettere;
  i=[1,2,3,4,5,6,7,8,9,10];
  esitoSave;
  nome;
  editor_m2;
  editor_cs;
  editor_cdcp;
  access_level;

  constructor(public service: GlobalServices, private ruoter: Router, private notifiche_bottom: MatSnackBar) { }

  ngOnInit() {
    this.service.get_function(this.service.apiGetImpostazioni+'cs').subscribe(data=>this.cs=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'m2').subscribe(data=>this.m2=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'cdcp').subscribe(data=>this.cdcp=JSON.parse(data[0].value));
    this.access_level=this.service.get_access_level();
  }

}
