<mat-sidenav-container>
  
<mat-sidenav mode="side" [opened]="true">
    <mat-list>
    <div *ngIf="access_level==1 || access_level==6 || access_level==7 || access_level==8">
    <h3 mat-subheader class="red"   (click)="SelectIndex(0,0)"> Cardiologia </h3>
        <mat-list-item><button mat-button (click)="SelectIndex(1,0)"> Visita ed ECG </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,1)"> Ecocardiogramma Color-Doppler </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,2)"> Eco-Color-Doppler TSA </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,3)"> Doppler arti superiori/inferiori </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,4)"> ECG da Sforzo al Cicloergometro </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,5)"> Test del Cammino </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(1,6)"> Refertazione Libera Privata </button></mat-list-item>
    </div>
    <div *ngIf="access_level==1 || access_level==10">
    <h3 mat-subheader class="green"  (click)="SelectIndex(0,1)"> Pneumologia </h3>
        <mat-list-item *ngIf="access_level==1 || access_level==10"><button mat-button (click)="SelectIndex(2,0)"> Visita </button></mat-list-item>
        <mat-list-item *ngIf="access_level==1 || access_level==10"><button mat-button (click)="SelectIndex(2,1)"> Prescrizione FKT </button></mat-list-item>
        <mat-list-item *ngIf="access_level==1 || access_level==10"><button mat-button (click)="SelectIndex(2,2)"> Prick Test</button></mat-list-item>
        <mat-list-item *ngIf="access_level==1 || access_level==10 || access_level==3"><button mat-button (click)="SelectIndex(2,3)"> Test del Cammino</button></mat-list-item>
    </div>
    <div *ngIf="access_level==1 || access_level==9">
    <h3 mat-subheader class="blue"  (click)="SelectIndex(0,2)"> Fisiatria </h3>
        <mat-list-item><button mat-button (click)="SelectIndex(3,0)"> P.R.I. e Scheda </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(3,1)"> Elettromiografia </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(3,2)"> Esame Baropodometrico </button></mat-list-item>
        <mat-list-item><button mat-button (click)="SelectIndex(3,3)"> Esito Terapia Inail </button></mat-list-item>
    </div>
    </mat-list>
</mat-sidenav>
<mat-sidenav-content>
<mat-card style="min-height:800px;">
    <mat-card-title [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }">
        Crea referto per {{paziente.cognome}} {{paziente.nome}}, paziente di anni {{ecg_sforzo.eta}}
    </mat-card-title>
    <mat-card-content>            
        <mat-tab-group [selectedIndex]="selectBranca">
            <mat-tab label="Cardiologia" *ngIf="access_level==1 || access_level==6 || access_level==7 || access_level==8">
                <mat-card>
                    <mat-card-content>
                            <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Informazioni di base
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            Informazioni raccolte dal personale infermieristico
                                        </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="33%">                          
                                                <mat-form-field fxFlexFill>
                                                <input matInput placeholder="Altezza" [(ngModel)]="anamnesi.altezza" name="anamnesi.altezza">
                                                <span matSuffix>cm</span>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="33%">  
                                                <mat-form-field fxFlexFill>
                                                <input matInput placeholder="Peso" [(ngModel)]="anamnesi.peso" name="anamnesi.peso">
                                                <span matSuffix>Kg</span>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="34%"> 
                                                <mat-form-field fxFlexFill>
                                                <input matInput placeholder="p.A." [(ngModel)]="anamnesi.pa" name="anamnesi.pa">
                                                <span matSuffix>mmHg</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="25%">
                                                    <mat-form-field fxFlexFill>
                                                            <mat-select placeholder="Fumatore" [(ngModel)]="anamnesi.fumatore" name="anamnesi.fumatore">
                                                                    <mat-option value="No">No</mat-option>
                                                                    <mat-option value="Si">Si</mat-option>
                                                                    <mat-option value="Ex">Ex</mat-option>
                                                            </mat-select>
                                                    </mat-form-field>
                                            </div>
                                            <div fxFlex="25%">
                                                    <mat-form-field fxFlexFill>
                                                            <mat-select placeholder="Ipertensione arteriosa" [(ngModel)]="anamnesi.ipertensione_arteriosa" name="anamnesi.ipertensione_arteriosa">
                                                                <mat-option value="No">No</mat-option>
                                                                <mat-option value="Si">Si</mat-option>
                                                            </mat-select>
                                                    </mat-form-field>
                                            </div>
                                    
                                            <div fxFlex="25%">
                                                    <mat-form-field fxFlexFill>
                                                            <mat-select placeholder="Diabete" [(ngModel)]="anamnesi.diabete" name="anamnesi.diabete">
                                                                <mat-option value="No">No</mat-option>
                                                                <mat-option value="Si">Si</mat-option>
                                                            </mat-select>
                                                    </mat-form-field>
                                            </div>
                                            <div fxFlex="25%">
                                                    <mat-form-field fxFlexFill>
                                                            <mat-select placeholder="Dislipidemia" [(ngModel)]="anamnesi.dislipidemia" name="anamnesi.dislipidemia">
                                                                <mat-option value="No">No</mat-option>
                                                                <mat-option value="Si">Si</mat-option>
                                                            </mat-select>
                                                    </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="50%">
                                                <mat-form-field fxFlexFill>
                                                    <textarea matInput placeholder="Altre diagnosi" [(ngModel)]="anamnesi.precedenti_diagnosi" name="anamnesi.precedenti_diagnosi"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="50%">
                                                <mat-form-field fxFlexFill>
                                                    <textarea matInput placeholder="Farmaci assunti" [(ngModel)]="anamnesi.farmaci_assunti" name="anamnesi.farmaci_assunti"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="SalvaAnamnesi()">Salva</button>&nbsp;&nbsp;&nbsp;&nbsp;<span class="hint">{{anamnesi_salvata}}</span>
                                        </div><br/>
                                        <div fxLayout="row">
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="service.print('InformativaSforzo','',today,paziente,nominativo,anamnesi,'Cardiologia',1,impostazioni_cdcp)">Stampa informativa per ECG da Sforzo</button>
                                        </div>
                                    </mat-expansion-panel>
                            </mat-accordion>                        
                        <mat-tab-group [selectedIndex]="selectCardiologia" *ngIf="access_level==1 || access_level==8">
                            <mat-tab label="Visita + ECG">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="70%">
                                        (I campi non valorizzati non verranno inseriti nel referto finale)
                                    </div>
                                    <div fxFlex="30%"><b><i>TESTI PREIMPOSTATI</i></b></div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="70%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="3" matInput placeholder="Sintesi Anamnestica" [(ngModel)]="visitaC.motivo" name="visitaC.motivo"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="30%">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Non riferisce sintomatologia di pertinenza di particolare rilievo',1)">Nessuna sintomatologia</button>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="70%">
                                            <mat-form-field fxFlexFill>
                                                <textarea rows="8" matInput placeholder="Esame Obiettivo" [(ngModel)]="visitaC.esameo" name="visitaC.esameo"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="30%">
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Toni ritmici, pause libere',2)">Toni ritmici, pause libere</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Polsi periferici presenti, simmetrici e normosfigmici',2)">Polsi periferici presenti, simmetrici e normosfigmici</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('M.V. presente su tutto l’ambito, normotrasmesso',2)">M.V. presente su tutto l’ambito, normotrasmesso</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Reperto da BPCO',2)">Reperto da BPCO</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Condizioni di sufficiente compenso emodinamico',2)">Condizioni di sufficiente compenso emodinamico</button><br/>
                                        </div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="70%">
                                            <mat-form-field fxFlexFill>
                                                <textarea rows="3" matInput placeholder="ECG" [(ngModel)]="visitaC.ecg" name="visitaC.ecg"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="30%">
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Ritmo sinusale a frequenza di ; nei limiti della norma',3)">Ritmo sinusale a frequenza di ; nei limiti della norma</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Ritmo sinusale a frequenza di ; deviazione assiale sinistra',3)">Ritmo sinusale a frequenza di ; deviazione assiale sinistra</button><br/>
                                        </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="3" matInput placeholder="Diagnosi" [(ngModel)]="visitaC.diagnosi" name="visitaC.diagnosi"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="70%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="8" matInput placeholder="Note" [(ngModel)]="visitaC.note" name="visitaC.note"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="30%">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Dieta iposodica',5)">Dieta iposodica</button><br/>
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Dieta iposodica – ipocalorica',5)">Dieta iposodica – ipocalorica</button><br/>
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Controllo regolare della P.A.',5)">Controllo regolare della P.A.</button><br/>
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Attività fisica costante aerobica (es.: cammino a passo sostenuto per ',5)">Attività fisica costante (es.: cammino a passo sostenuto per...</button><br/>
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Smettere di fumare',5)">Smettere di fumare</button><br/>
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Controllo della pressione per una settimana ore 8.00 prima di assumere la terapia, ore 12.00 e ore 18.00',5)">Controllo domiciliare della pressione</button><br/>
                                    </div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                            <mat-form-field fxFlexFill>
                                                <textarea rows="8" matInput placeholder="Prescrizioni" [(ngModel)]="visitaC.prescrizioni" name="visitaC.prescrizioni"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="30%">
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Si consiglia eseguire esami ematochimici di ruotine',6)">Si consiglia eseguire esami ematochimici di ruotine</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Si consiglia eseguire Eco cuore 2D color doppler',6)">Si consiglia eseguire Eco cuore 2D color doppler</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Si consiglia eseguire Ecg da sforzo',6)">Si consiglia eseguire Ecg da sforzo</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Si consiglia eseguire Ecg secondo Holter',6)">Si consiglia eseguire Ecg secondo Holter</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('Si consiglia eseguire Monitoraggio ambulatoriale della P.A.',6)">Si consiglia eseguire Monitoraggio ambulatoriale della P.A.</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('89442 Test del Cammino',6)">Test del Cammino</button><br/>
                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="AggiungiTesto('897(10167) Prima Visita Pneumologica, 89372(49321) Spirometria globale, 89381(49371) Resistenze delle vie aeree, 89384(49401) Compliance polmonare statica e dinamica, 89385(49411) Determinazione del pattern respiratorio a riposo, 89387(49431) Determinazione delle massime pressioni inspiratorie ed espiratorie, 89389(49451) Determinazione della Po.1',6)">Visita Pneumologica e Spirometria</button><br/>
                                            
                                        </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="1" matInput placeholder="Prossimo controllo" [(ngModel)]="visitaC.data_prossimo" name="visitaC.data_prossimo"></textarea>
                                        </mat-form-field>
                                    </div>
                            </div>
                                <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('visitaC')">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Ecocardiogramma Color-Doppler">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="MITRALE" [(ngModel)]="EcoD.mitrale" name="EcoD.mitrale"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="AORTA" [(ngModel)]="EcoD.aorta" name="EcoD.aorta"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="ATRIO SINISTRO" [(ngModel)]="EcoD.atrio_sinistro" name="EcoD.atrio_sinistro"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="VENTRICOLO SINISTRO" [(ngModel)]="EcoD.ventricolo_sinistro" name="EcoD.ventricolo_sinistro"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="CUORE DESTRO" [(ngModel)]="EcoD.cuore_destro" name="EcoD.cuore_destro"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="DOPPLER" [(ngModel)]="EcoD.doppler" name="EcoD.doppler"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="MISURE VOLUMETRICHE" [(ngModel)]="EcoD.misure_volumetriche" name="EcoD.misure_volumetriche"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="NOTE" [(ngModel)]="EcoD.note" name="EcoD.note"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="CONCLUSIONI" [(ngModel)]="EcoD.conclusioni" name="EcoD.conclusioni"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('EcoD')">Salva e Stampa</button>
                            </div>
                            
                            </mat-tab>
                            <mat-tab label="Eco-Color-Doppler TSA">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="10" matInput placeholder="Referto" [(ngModel)]="tsa" name="tsa"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('TSA')">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Doppler arti superiori/inferiori">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="10" matInput placeholder="Referto" [(ngModel)]="doppler_arti" name="doppler_arti"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('DOPPLER')">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="ECG da Sforzo al Cicloergometro">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput placeholder="Terapia" [(ngModel)]="ecg_sforzo.terapia" name="ecg_sforzo.terapia"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <h2>Risultati</h2>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="20%">
                                        <h3>Base</h3>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input (change)="DP('base')" matInput placeholder="Frequenza cardiaca" [(ngModel)]="ecg_sforzo.frequenza_base" name="ecg_sforzo.frequenza_base">
                                            <span matSuffix> /m'</span>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="12%">
                                        <mat-form-field fxFlexFill>
                                            <input (change)="DP('base')" matInput placeholder="P.Sist." [(ngModel)]="ecg_sforzo.pressione_max_base" name="ecg_sforzo.pressione_max_base">
                                            <span matSuffix> / </span>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="12%">
                                        <mat-form-field fxFlexFill>
                                                <input matInput placeholder="P.Diast." [(ngModel)]="ecg_sforzo.pressione_min_base" name="ecg_sforzo.pressione_min_base">
                                                <span matSuffix> mmHg</span>
                                            </mat-form-field>
                                    </div>
                                    <div fxFlex="25%">
                                        <mat-form-field fxFlexFill>
                                            <input disabled="disabled" matInput placeholder="Doppio prodotto" [(ngModel)]="ecg_sforzo.doppio_prodotto_base" name="ecg_sforzo.doppio_prodotto_base">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="20%">
                                            <h3>Lavoro massimo</h3>
                                        </div>
                                        <div fxFlex="20%">
                                            <mat-form-field fxFlexFill>
                                                <input (change)="DP('max')"  matInput placeholder="Frequenza cardiaca" [(ngModel)]="ecg_sforzo.frequenza" name="ecg_sforzo.frequenza">
                                                <span matSuffix> /m'</span>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="12%">
                                            <mat-form-field fxFlexFill>
                                                <input (change)="DP('max')" matInput placeholder="P.Sist." [(ngModel)]="ecg_sforzo.pressione_max" name="ecg_sforzo.pressione_max">
                                                <span matSuffix> / </span>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="12%">
                                            <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="P.Diast." [(ngModel)]="ecg_sforzo.pressione_min" name="ecg_sforzo.pressione_min">
                                                    <span matSuffix> mmHg</span>
                                                </mat-form-field>
                                        </div>
                                        <div fxFlex="25%">
                                            <mat-form-field fxFlexFill>
                                                <input disabled="disabled" matInput placeholder="Doppio prodotto" [(ngModel)]="ecg_sforzo.doppio_prodotto" name="ecg_sforzo.doppio_prodotto">
                                            </mat-form-field>
                                        </div>
                                 </div><br/>
                                 <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                            Raggiunto il {{100*(ecg_sforzo.frequenza/(220-ecg_sforzo.eta)) | number:'2.1-2'}}% della frequenza cardiaca massima teorica
                                        </div>
                                </div><br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                            Il Test è stato interrotto al carico massimo di  <mat-form-field>
                                                    <input matInput [(ngModel)]="ecg_sforzo.interrotto" name="ecg_sforzo.interrotto">
                                                </mat-form-field>
                                        </div>
                                </div><br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                             <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="Motivo" [(ngModel)]="ecg_sforzo.motivo" name="ecg_sforzo.motivo">
                                            </mat-form-field>
                                        </div>
                                </div><br/>
                                <h2>Durante il test sono comparsi</h2><br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                             <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="Sintomi soggettivi" [(ngModel)]="ecg_sforzo.sintomi_soggettivi" name="ecg_sforzo.sintomi_soggettivi">
                                                </mat-form-field>
                                        </div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                             <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="Modificazioni del tratto ST-T" [(ngModel)]="ecg_sforzo.modificazioni_st" name="ecg_sforzo.modificazioni_st">
                                                </mat-form-field>
                                        </div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                             <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="Aritmie" [(ngModel)]="ecg_sforzo.aritmie" name="ecg_sforzo.aritmie">
                                                </mat-form-field>
                                        </div>
                                </div>
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="100%">
                                             <mat-form-field fxFlexFill>
                                                    <input matInput placeholder="Turbe della Conduzione" [(ngModel)]="ecg_sforzo.turbe_conduzione" name="ecg_sforzo.turbe_conduzione">
                                            </mat-form-field>
                                        </div>
                                </div>
                                <br/>
                                <h2>Conclusioni</h2>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="5" matInput [(ngModel)]="ecg_sforzo.conclusioni" name="ecg_sforzo.conclusioni"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('TestSforzo')">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Test del Cammino">
                                <br>
                                <h2 style="text-align: center;">TEST DEL CAMMINO</h2>
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <div fxFlex="25%">
                                        Sesso: <mat-select [(ngModel)]="testC.sesso" name="testC.sesso">
                                            <mat-option value="M">M</mat-option>
                                            <mat-option value="F">F</mat-option>
                                        </mat-select>
                                    </div>
                                    <div fxFlex="25%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Altezza (cm)" [(ngModel)]="testC.altezza" name="testC.altezza">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Peso (kg)" [(ngModel)]="testC.peso" name="testC.peso">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Fi O2(%)" [(ngModel)]="testC.fi" name="testC.fi">
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <b>Indice di Massa Corporea (BMI kg/m2) (bpm): </b><i>{{(testC.peso*10000)/(testC.altezza*testC.altezza)}}</i>
                                    </div>
                                    <div>
                                        <b>Frequenza Cardiaca Max (bpm): </b><i>{{208-(ecg_sforzo.eta * 0.7)}}</i>
                                    </div>
                                    <div>
                                        <b>Percorrenza Teorica (m): </b><i *ngIf="testC.sesso=='M'">{{(7.57*testC.altezza)-(5.02*ecg_sforzo.eta)-(1.76*testC.peso)-309}}</i><i *ngIf="testC.sesso=='F'">{{(2.11*testC.altezza)-(5.88*ecg_sforzo.eta)-(2.29*testC.peso)+667}}</i>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <h4>PRE</h4>
                                </div>
                                <div fxLayout="row" fxLayoutGap="5px">
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="SAT HB-O2 %" [(ngModel)]="testC.sat_pre" name="testC.sat_pre">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="FREQUENZA CARDIACA (bpm)" [(ngModel)]="testC.bpm_pre" name="testC.bpm_pre">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="BORG (range 1-10)" [(ngModel)]="testC.borg_pre" name="testC.borg_pre">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <h4>POST</h4>
                                </div>
                                <div fxLayout="row" fxLayoutGap="5px">
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="SAT HB-O2 %" [(ngModel)]="testC.sat_post" name="testC.sat_post">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="FREQUENZA CARDIACA (bpm)" [(ngModel)]="testC.bpm_post" name="testC.bpm_post">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="BORG (range 1-10)" [(ngModel)]="testC.borg_post" name="testC.borg_post">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <b>% Frequenza Cardiaca raggiunta (bpm): </b><i>{{(100*testC.bpm_post)/(208-(ecg_sforzo.eta * 0.7))}}</i>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="5px">
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Percorrenza misurata" [(ngModel)]="testC.percorrenza_misurata" name="testC.percorrenza_misurata">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="20%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Tempo di recupero" [(ngModel)]="testC.recupero" name="testC.recupero">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <input matInput placeholder="Note e Conclusioni" [(ngModel)]="testC.note" name="testC.note">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('TestCamminoCDCP')" class="bottom-right">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Refertazione Libera Privata">
                                <br/>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                            <textarea rows="10" matInput placeholder="Referto" [(ngModel)]="refertazione_privata" name="refertazione_privata"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" color="primary" mat-raised-button (click)="Salva('refertazione_privata')">Salva e Stampa</button>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            <mat-tab label="Pneumologia" *ngIf="access_level==1 || access_level==10 || access_level==3">
                <mat-tab-group [selectedIndex]="selectPneumologia">
                    <mat-tab label="Visita">
                        <br/>
                        
                        <div fxLayout="row" fxLayoutGap="10px">
                            <div fxFlex="25%">
                                <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Riferimento" [(ngModel)]="vp.riferimento" name="vp.riferimento">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%">
                                <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Professione" [(ngModel)]="vp.professione" name="vp.professione">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%" style="text-align: center">
                                Data Prima Visita:<br/><span class="titoletto">{{data_prima_vp | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div fxFlex="25%" style="text-align: center">
                                Data Ultima Visita:<br/><span class="titoletto">{{data_ultima_vp | date:'dd/MM/yyyy'}}</span>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px">
                            <div fxFlex="60%"><i><b>Ultimo referto presente:</b></i></div>
                            <div fxFlex="60%" style="white-space: pre-wrap"><i>{{visitaP.precedente}}</i></div>
                        </div>
                        <br/>
                        <div fxLayout="column" fxLayoutGap="10px">
                            <div fxFlex="60%"><i><b>Seleziona una delle voci per proseguire nella visita</b></i></div>
                            <div fxFlex="60%" style="white-space: pre-wrap">
                                <mat-radio-group class="radio-group" [(ngModel)]="visitaP.spirometria" name="visitaP.spirometria">  
                                    <mat-radio-button value="{{spiro[0]}}">{{spiro[0]}}</mat-radio-button>
                                    <mat-radio-button value="{{spiro[1]}}">{{spiro[1]}}</mat-radio-button>
                                    <mat-radio-button value="{{spiro[2]}}">{{spiro[2]}}</mat-radio-button>
                                    <mat-radio-button value="{{spiro[3]}}">{{spiro[3]}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px" *ngIf="visitaP.spirometria || access_level==1">
                            <div fxFlex="100%">
                            <mat-card>
                                <mat-card-title>Anamnesi</mat-card-title>
                                <mat-card-content>
                                    <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="60%">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlexFill>
                                                        <textarea rows="4" matInput [(ngModel)]="visitaP.anamnesi" name="visitaP.anamnesi"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex="40%">
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="50%">
                                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('anamnesi','Non fumatore')">Non fumatore</button>
                                                </div>
                                                <div fxFlex="50%">
                                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('anamnesi','Ex fumatore')">Ex fumatore</button>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="33%">
                                                    <mat-form-field fxFlexFill>
                                                        <input matInput placeholder="Sigarette/giorno" [(ngModel)]="cig" name="cig">
                                                </mat-form-field>
                                                </div>
                                                <div fxFlex="33%">
                                                    <mat-form-field fxFlexFill>
                                                        <input matInput placeholder="Anni da fumatore" [(ngModel)]="years" name="years">
                                                </mat-form-field>
                                                </div>
                                                <div fxFlex="33%">
                                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('anamnesi','PackYears')">Calcola Pack Years</button>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="67%">
                                                    <mat-form-field fxFlexFill>
                                                        <input matInput placeholder="Attività lavorativa" [(ngModel)]="lavoro" name="lavoro">
                                                </mat-form-field>
                                                </div>
                                                <div fxFlex="33%">
                                                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('anamnesi','Lavoro')">Aggiungi</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            </div>
                            <div fxFlex="100%">
                            <mat-card>
                                <mat-card-title>E.O. Toracico</mat-card-title>
                                <mat-card-content>
                                    <div fxLayout="row" fxLayoutGap="10px">
                                        <div fxFlex="60%">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlexFill>
                                                    <textarea rows="4" matInput [(ngModel)]="visitaP.eo" name="visitaP.eo"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex="40%">
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('eo','Murmure vescicolare normotrasmesso su tutto l\'ambito, non rumori aggiunti')">Murmure normotrasmesso</button></div>
                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('eo','Murmure ridotto su tutto l\'ambito, non rumori aggiunti')">Murmure ridotto</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            </div>
                            <div fxFlex="100%">
                                <mat-card>
                                    <mat-card-title>Diagnosi</mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="60%">
                                                <div fxLayout="row">
                                                    <mat-form-field fxFlexFill>
                                                        <textarea rows="8" matInput [(ngModel)]="visitaP.diagnosi" name="visitaP.diagnosi"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxFlex="40%">
                                                <div fxLayout="column" fxLayoutGap="5px">
                                                    <div fxFlex="100%">
                                                        <h4>Diagnosi rapide</h4>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','BPCO')">BPCO</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Asma bronchiale')">Asma bronchiale</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Bronchite cronica')">Bronchite cronica</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','OSAS')">OSAS</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','ACOS')">ACOS</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Asma &quot;DIFFICILE&quot;')">Asma DIFFICILE</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Patologia diffusa del polmone')">Patologia diffusa del polmone</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Coin lesion')">Coin lesion</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Rinite allergica')">Rinite allergica</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Bronchiectasie')">Bronchiectasie</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Dispnea in obesità morbigena')">Dispnea in obesità morbigena</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Non evidenza di patologie dell\'apparato respiratorio')">Nessuna patologia</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('diagnosi','Iperattività bronchiale')">Iperattività bronchiale</button></div>
                                                            <div fxFlex="50%"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div fxFlex="100%" *ngIf="visitaP.spirometria==spiro[2] || visitaP.spirometria==spiro[3]">
                                <mat-card>
                                    <mat-card-title>
                                        Modulo nota 99 AIFA
                                    </mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="column" >
                                            <div fxFlex="100%"> <mat-checkbox [(ngModel)]="visitaP.nota99.diagnosi" name="visitaP.nota99.diagnosi">diagnosi clinica e spirometrica di broncopneumopatia cronica ostruttiva (BPCO)</mat-checkbox></div>
                                            <br/>
                                            <div fxFlex="100%">
                                                <div fxLayout="row" fxLayout.xs="column">
                                                    <div fxFlex="50%">
                                                        FEV1 / FVC (da esame spirometrico) < 0,70 (70%)
                                                    </div>
                                                    <div fxFlex="50%">
                                                        <mat-form-field fxFlexFill> <mat-label>Indicare il valore misurato con lo spirometro dopo broncodilatazione</mat-label><input matInput [(ngModel)]="visitaP.nota99.tiffenau" name="visitaP.nota99.tiffenau" placeholder="Indicare il valore misurato con lo spirometro dopo broncodilatazione"></mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div fxFlex="100%">
                                                <div fxLayout="row" fxLayout.xs="column">
                                                    <div fxFlex="50%">
                                                        <mat-radio-group [(ngModel)]="visitaP.nota99.fev1" name="visitaP.nota99.dev1">  
                                                            <mat-radio-button value="FEV1 > 50%">FEV1 > 50%</mat-radio-button>
                                                            &nbsp;&nbsp;oppure&nbsp;&nbsp;
                                                            <mat-radio-button value="FEV1 < 50%">FEV1 < 50%</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div fxFlex="50%">
                                                        <mat-form-field fxFlexFill> <mat-label>Indicare il valore misurato con lo spirometro</mat-label><input matInput [(ngModel)]="visitaP.nota99.fev1misurato" name="visitaP.nota99.fev1misurato" placeholder="Indicare il valore misurato con lo spirometro"></mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <div fxFlex="100%">
                                                <b>Terapia inalatoria in atto:</b>
                                            </div>
                                            <div fxFlex="100%">
                                                <mat-radio-group class="radio-group" [(ngModel)]="visitaP.nota99.terapia_in_atto" name="visitaP.nota99.terapia_in_atto">
                                                    <mat-radio-button value="LAMA">LAMA</mat-radio-button>
                                                    <mat-radio-button value="LABA">LABA</mat-radio-button>
                                                    <mat-radio-button value="LABA+LAMA">LABA+LAMA</mat-radio-button>
                                                    <mat-radio-button value="LABA+ICS">LABA+ICS</mat-radio-button>
                                                    <mat-radio-button value="LABA+ICS+LAMA">LABA+ICS+LAMA</mat-radio-button>
                                                    <mat-radio-button value="altro">altro</mat-radio-button>
                                                </mat-radio-group>
                                                <mat-form-field fxFlexFill> <mat-label>Dettagli sulla terapia in atto</mat-label><input matInput [(ngModel)]="visitaP.nota99.descrizione_terapia_in_atto" name="visitaP.nota99.descrizione_terapia_in_atto" placeholder="Dettagli sulla terapia in atto"></mat-form-field>
                                            </div>
                                            <div fxFlex="100%">
                                                <h2>Sezione 1: valutazione clinica e proposta di strategia terapeutica</h2>
                                            </div>
                                            <div fxFlex="100%">
                                                <b>Valutazione clinica della terapia in corso</b>
                                            </div>
                                            <br/>
                                            <div fxFlex="100%">
                                                <mat-radio-group class="radio-group" [(ngModel)]="visitaP.nota99.valutazione_clinica" name="visitaP.nota99.valutazione_clinica">
                                                    <mat-radio-button value="Terapia efficace: conferma della terapia in atto ">Terapia efficace: conferma della terapia in atto </mat-radio-button>
                                                    <mat-radio-button value="Terapia inefficace o parzialmente efficace per permanenza della dispnea">Terapia inefficace o parzialmente efficace per permanenza della dispnea</mat-radio-button>
                                                    <mat-radio-button value="Terapia inefficace o parzialmente efficace per permanenza di riacutizzazioni">Terapia inefficace o parzialmente efficace per permanenza di riacutizzazioni</mat-radio-button>
                                                    <mat-radio-button value="Terapia inefficace o parzialmente efficace per permanenza sia di riacutizzazioni sia di dispnea">Terapia inefficace o parzialmente efficace per permanenza sia di riacutizzazioni sia di dispnea</mat-radio-button>
                                                    <mat-radio-button value="Terapia non tollerata">Terapia non tollerata</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <br/>
                                            <div fxFlex="100%">
                                                <b>Proposta di strategia terapeutica</b>
                                            </div>
                                            <div fxFlex="100%">
                                                <mat-radio-group class="radio-group" [(ngModel)]="visitaP.nota99.proposta_terapia" name="visitaP.nota99.proposta_terapia">
                                                    <mat-radio-button value="LAMA">LAMA</mat-radio-button>
                                                    <mat-radio-button value="LABA">LABA</mat-radio-button>
                                                    <mat-radio-button value="LABA+LAMA">LABA+LAMA</mat-radio-button>
                                                    <mat-radio-button value="LABA+ICS">LABA+ICS</mat-radio-button>
                                                    <mat-radio-button value="LABA+ICS+LAMA (unico inalatore)">LABA+ICS+LAMA (unico inalatore)*</mat-radio-button>
                                                    <mat-radio-button value="altro">altro</mat-radio-button>
                                                </mat-radio-group>
                                                <mat-form-field fxFlexFill> <mat-label>Dettagli sulla terapia proposta</mat-label><input matInput [(ngModel)]="visitaP.nota99.descrizione_proposta_terapia" name="visitaP.nota99.descrizione_proposta_terapia" placeholder="Dettagli sulla terapia proposta"></mat-form-field>
                                            </div>
                                            <div fxFlex="100%">
                                                <p><i>* per la prescrizione  di un LABA + ICS + LAMA (unico inalatore) è necessario il PT (compilare la sezione 2)</i></p>
                                            </div>
                                            <div fxFlex="100%" *ngIf="visitaP.nota99.proposta_terapia=='LABA+ICS+LAMA (unico inalatore)'">
                                                <h2>Sezione 2: Piano Terapeutico AIFA (unico per le triplici associazioni fisse)</h2>
                                                <p><b>Piano Terapeutico AIFA per la prescrizione della TRIPLICE terapia LABA/LAMA/ICS (in singoloerogatore) nel trattamento della broncopneumopatia cronica ostruttiva (BPCO)</b></p>
                                                <p>La rimborsabilità SSN è limitata alla terapia di mantenimento dei pazienti adulti con diagnosi di BPCO di grado da moderato a severo, che non siano sufficientemente controllati dall’associazione di un corticosteroide inalatorio e di un beta2-agonista a lunga durata d’azione, laddove siano soddisfatte <b>ENTRAMBE</b> le condizioni di seguito riportate:</p>
                                            </div>
                                            <div fxFlex="100%" *ngIf="visitaP.nota99.proposta_terapia=='LABA+ICS+LAMA (unico inalatore)'">
                                                <mat-checkbox [(ngModel)]="visitaP.nota99.test1" name="visitaP.nota99.test1">Paziente con diagnosi conclamata di BPCO da moderata a grave e con almeno 2 riacutizzazioni negli ultimi 12 mesi o almeno una riacutizzazione che ha richiesto il ricovero ospedaliero;</mat-checkbox>
                                            </div>
                                            <div fxFlex="100%" *ngIf="visitaP.nota99.proposta_terapia=='LABA+ICS+LAMA (unico inalatore)'">
                                                <mat-checkbox [(ngModel)]="visitaP.nota99.test2" name="visitaP.nota99.test2">Paziente affetto da BPCO con dispnea classificabile come ai punti A) oppure B): </mat-checkbox>
                            
                                                       <p>A) Dispnea di grado 2 o superiore misurata tramite il questionario mMRC per la dispnea:</p>
                                                        <h4>Questionario mMRC</h4>
                                                       <mat-radio-group class="radio-group" [(ngModel)]="visitaP.nota99.mmrc" name="visitaP.nota99.mmrc">
                                                            <mat-radio-button value="0">Grado 0 - Ho dispnea solo per sforzi intensi (es. salire le scale, pedalare).</mat-radio-button>
                                                            <mat-radio-button value="1">Grado 1 - Mi manca il fiato se cammino veloce (o corro) in piano o in lieve salita</mat-radio-button>
                                                            <mat-radio-button value="2">Grado 2 - Su percorsi piani cammino più lentamente dei miei coetanei, oppure ho necessità di fermarmi per respirare quando cammino a passo normale</mat-radio-button>
                                                            <mat-radio-button value="3">Grado 3 - Ho necessità di fermarmi per respirare dopo aver camminato in piano per circa 100 metri o per pochi minuti</mat-radio-button>
                                                            <mat-radio-button value="4">Grado 4 - Mi manca il fiato a riposo, per uscire di casa o per vestirmi/spogliarmi</mat-radio-button>
                                                        </mat-radio-group>
                                                    <p>oppure</p>
                                                        <p>B) Punteggio al test di valutazione della sintomatologia CAT pari almeno a 10</p>
                                                        <h4>Questionario CAT (COPD Assessment Test - CATTM)</h4>
                                                        <table>
                                                            <tr><td>Non tossisco mai</td>
                                                            <td>
                                                                <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat1" name="visitaP.nota99.cat1">
                                                                    <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                    <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                    <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                    <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                    <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                    <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                </mat-radio-group>
                                                            </td>
                                                            <td>Tossisco sempre</td></tr>
                                                            <tr><td>Il mio petto è completamente libero da catarro (muco)</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat2" name="visitaP.nota99.cat2">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Il mio petto è tutto pieno di catarro (muco)</td></tr>
                                                            <tr><td>Non avverto alcuna sensazione di costrizione al petto</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat3" name="visitaP.nota99.cat3">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Avverto una forte sensazione di costrizione al petto</td></tr>
                                                            <tr><td>Quando cammino in salita o salgo una rampa di scale non avverto mancanza di fiato</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat4" name="visitaP.nota99.cat4">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Quando cammino in salita o salgo una rampa di scale avverto una forte mancanza di fiato</td></tr>
                                                            <tr><td>Non avverto limitazioni nello svolgere qualsiasi attività in casa</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat5" name="visitaP.nota99.cat5">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Avverto gravi limitazioni nello svolgere qualsiasi attività in casa</td></tr>
                                                            <tr><td>Mi sento tranquillo ad uscire di casa nonostante la mia malattia polmonare</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat6" name="visitaP.nota99.cat6">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Non mi sento affatto tranquillo ad uscire di casa a causa della mia malattia polmonare</td></tr>
                                                            <tr><td>Dormo profondamente</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat7" name="visitaP.nota99.cat7">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Non riesco a dormire profondamente a causa della mia malattia polmonare</td></tr>
                                                            <tr><td>Ho molta energia</td>
                                                                <td>
                                                                    <mat-radio-group (change)="chgRadio()" [(ngModel)]="visitaP.nota99.cat8" name="visitaP.nota99.cat8">
                                                                        <mat-radio-button value=0>0&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=1>1&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=2>2&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=3>3&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=4>4&nbsp;&nbsp;</mat-radio-button>
                                                                        <mat-radio-button value=5>5&nbsp;&nbsp;</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </td>
                                                                <td>Non ho nessuna energia</td></tr>
                                                            <tr><td colspan="2" style="text-align: right"><b>PUNTEGGIO TOTALE</b></td><td><b>{{visitaP.nota99.cat_totale}}</b></td></tr>
                                                        </table>
                                                        <br/><br/>
                                                        <table>
                                                            <tr><th> <mat-checkbox [(ngModel)]="visitaP.nota99.prima_prescrizione" name="visitaP.nota99.prima_prescrizione">PRIMA PRESCRIZIONE</mat-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-checkbox [(ngModel)]="visitaP.nota99.prosecuzione_terapia" name="visitaP.nota99.prosecuzione_terapia">PROSECUZIONE TERAPIA</mat-checkbox></th></tr>
                                                            <tr><td style="text-align: right">Posologia</td></tr>
                                                            <mat-radio-group [(ngModel)]="visitaP.nota99.posologia" name="visitaP.nota99.posologia">
                                                                <tr>
                                                                    <td><mat-radio-button value="Beclometasone dipropionato/formoterolo fumarato diidrato/glicopirronio bromuro">Beclometasone dipropionato/formoterolo fumarato diidrato/glicopirronio bromuro</mat-radio-button></td>
                                                                    <td>2 erogazioni (ciascuna da 87 mcg/5 mg/9 mcg) due volte al giorno.</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><mat-radio-button value="Fluticasone furorato/umeclidinio bromuro/vilanterolo trifenatato">Fluticasone furorato/umeclidinio bromuro/vilanterolo trifenatato</mat-radio-button></td>
                                                                    <td>1 inalazione (da 92 mcg/55 mg/22 mcg) ogni giorno alla stessa ora.</td>
                                                                </tr>
                                                            </mat-radio-group>
                                                            <tr><td> <mat-form-field fxFlexFill> <mat-label>Nome del Farmaco</mat-label><input matInput [(ngModel)]="visitaP.nota99.descrizione_posologia" name="visitaP.nota99.descrizione_posologia" placeholder="Nome del farmaco"></mat-form-field></td></tr>
                                                        </table>
                                                        <h4>NB per tutti i farmaci le dosi indicate sono anche le dosi massime raccomandate</h4>
                                                        <h4>Validità del Piano Terapeutico: <mat-select [(ngModel)]="visitaP.nota99.validita" name="visitaP.nota99.validita" style="width: 60px"><mat-option value="1">1</mat-option><mat-option value="2">2</mat-option><mat-option value="3">3</mat-option><mat-option value="4">4</mat-option><mat-option value="5">5</mat-option><mat-option value="6">6</mat-option><mat-option value="7">7</mat-option><mat-option value="8">8</mat-option><mat-option value="9">9</mat-option><mat-option value="10">10</mat-option><mat-option value="11">11</mat-option><mat-option value="12">12</mat-option></mat-select> mesi</h4>
                                            </div>

                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div fxFlex="100%">
                                <mat-card>
                                    <mat-card-title>Prescrizioni farmaceutiche</mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="60%">
                                                <div fxLayout="row">
                                                    <mat-form-field fxFlexFill>
                                                        <textarea rows="4" matInput [(ngModel)]="visitaP.prescrizioni_farmaceutiche" name="visitaP.prescrizioni_farmaceutiche"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxFlex="40%">
                                               <!-- <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Relvar Ellipta 92/22 S.: 1 inalazione/dì ore 8.00')">Relvar 92/22</button>
                                                    </div>
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Ialoclean S.: 1 inalazione tre volte al dì')">Ialoclean</button>
                                                    </div>
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Rolufta Ellipta S.: 1 inalazione/dì ore 17.00')">Rolufta</button>
                                                    </div>
                                                </div>-->
                                                <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.: Fluimucil 1fl, Lunibron 25gtt, Broncovaleas 5gtt \n S.:  in aerosol per due volte al dì (8/20) con mascherina per i primi 7 min \n indi aggiungere 5 gtt di rinazina e somministri per nasale per altri 3 min \n N.B. per 10gg')">Mix Aerosol</button>
                                                    </div>
                                                    <!--<div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Incruse Ellipta S.: 1 inalazione ore 8.00')">Incruse Ellipta</button>
                                                    </div>
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Trelegy S.: 1 inalazione/dì ore 8.00')">Trelegy</button>
                                                    </div>-->
                                                </div>
                                                <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Immubron cps x 30 \nS.: 1 cps al dì da sciogliere sotto la lingua per 10 giorni consecutivi al mese per 3 mesi consecutivi')">Immubron</button>
                                                    </div>
                                                    <!--<div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.:Revinty Ellipta 92/22 S.: 1 inalazione/dì ore 8.00')">Revinty 92/22</button>
                                                    </div>
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.: Ismigen S.: 1 compressa sublinguale per dieci gg al mese per tre mesi')">Ismigen</button>
                                                    </div>-->
                                                </div>
                                                <!--<div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="33%">
                                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_farmaceutiche','PR.: Elebrato Ellipta 92/55/22 S.: 1 inalazione/dì ore 8.00')">Elebrato 82/55/22</button>
                                                    </div>
                                                    <div fxFlex="33%">
                                                    </div>
                                                    <div fxFlex="33%">
                                                        
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            
                            <div fxFlex="100%">
                                <mat-card>
                                    <mat-card-title>Altre prescrizioni</mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="60%">
                                                <div fxLayout="row">
                                                    <mat-form-field fxFlexFill>
                                                        <textarea rows="8" matInput [(ngModel)]="visitaP.prescrizioni_altro" name="visitaP.prescrizioni_altro"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxFlex="40%">
                                                <div fxLayout="column" fxLayoutGap="5px">
                                                    <div fxFlex="100%">
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Tac Torace')">Tac Torace</button></div>
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Rx Torace A.P. e Lat')">Rx Torace</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                                <div fxFlex="60%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Spirometria')">Controllo con spirometria</button></div>
                                                                <div fxFlex="40%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Prick Test')">Prick Test</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="60%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','8901(12178) Visita Pneumologica di Controllo, 89372(49321) Spirometria globale, 89374(49341) Test di broncodilatazione farmacologica, 89381(49371) Resistenze delle vie aeree, 89384(49401) Compliance polmonare statica e dinamica, 89385(49411) Determinazione del pattern respiratorio a riposo, 89387(49431) Determinazione delle massime pressioni inspiratorie ed espiratorie, 89389(49451) Determinazione della Po.1')">Controllo, Spirometria e Beta2</button></div>
                                                            <div fxFlex="40%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','FKT')">FKT prescritta a parte</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Polisonnografia')">Polisonnografia</button></div>
                                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Prist e Rast per dermatophagoides farinae e pteronyssinus, graminacee, parietaria, cipresso, cane, gatto, olivo')">Prist e Rast</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="100%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Visita Cardiologica ECG ed Eco cuore 2D color doppler')">Visita Cardiologica, ECG ed Ecocolordoppler</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="100%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Eco cuore 2D color doppler')">Ecocolordoppler</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Test da Sforzo su ciclorgometro')">Test da Sforzo</button></div>
                                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Holter 24h')">Holter 24h</button></div>
                                                        </div>
                                                        <div fxLayout="row" fxLayoutGap="5px">
                                                            <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('prescrizioni_altro','Terapia immunosensibilizzante specifica')">TIS</button></div>
                                                            <div fxFlex="50%"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div fxFlex="100%">
                                <mat-card>
                                    <mat-card-title>Controllo</mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <div fxFlex="60%">
                                                <div fxLayout="row">
                                                    <mat-form-field fxFlexFill>
                                                        <textarea rows="4" matInput [(ngModel)]="visitaP.controllo" name="visitaP.controllo"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxFlex="40%">
                                                <div fxLayout="column" fxLayoutGap="5px">
                                                    <div fxFlex="100%">
                                                        <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('controllo','Controllo tra 1 mese')">1 mese</button></div>
                                                        <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('controllo','Controllo tra 2 mesi')">2 mesi</button></div>
                                                        <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('controllo','Controllo tra 3 mesi')">3 mesi</button></div>
                                                        <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('controllo','Controllo tra 6 mesi')">6 mesi</button></div>
                                                        
                                                    </div>
                                                    <div fxLayout="row" fxLayoutGap="5px">
                                                        <div fxFlex="80%">
                                                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('controllo','Visita di controllo con referti degli esami richiesti')">Controllo con esami richiesti</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div fxFlex="100%">
                                <mat-checkbox [(ngModel)]="visitaP.stampa" name="visitaP.stampa"><h3>Stampare precedenti?</h3></mat-checkbox>
                            </div>
                            <div fxFlex="100%">
                                <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('VisitaPneumo')" class="bottom-right">Salva e Stampa</button>
                            </div>
                            <!-- <div fxFlex="60%">
                                    <div fxLayout="row">
                                        <mat-form-field fxFlexFill>
                                                <textarea rows="15" matInput placeholder="Visita" [(ngModel)]="vp.visita" name="vp.visita"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('VisitaPneumo')" class="bottom-right">Salva e Stampa</button>
                                    </div>
                                    <div fxLayout="column" *ngIf="access_level==1">
                                        <div *ngFor="let t of vp.trattamenti; let i=index">
                                            <mat-checkbox [(ngModel)]="vp.trattamenti[i].valore" name="vp.trattamenti+{{i}}">{{vp.trattamenti[i].nome}}</mat-checkbox>
                                        </div>
                                    </div>
                            </div>
                            <div fxFlex="40%">
                                    <div fxLayout="column" fxLayoutGap="5px">
                                        <div fxFlex="100%">
                                            <h4>Diagnosi rapide</h4>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('BPCO')">BPCO</button></div>
                                                <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Asma bronchiale')">Asma bronchiale</button></div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Bronchite cronica')">Bronchite cronica</button></div>
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('OSAS')">OSAS</button></div>
                                            </div>
                                        </div>
                                        <div fxFlex="100%">
                                            <h4>Prescrizioni</h4>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="20%"><h5>Farmaci: </h5></div>
                                                <div fxFlex="40%">
                                                    <mat-form-field fxFlexFill>
                                                            <input matInput placeholder="Prescrizione" [(ngModel)]="pr" name="pr">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="40%">
                                                    <mat-form-field fxFlexFill>
                                                            <input matInput placeholder="Somministrazione" [(ngModel)]="somm" name="somm">
                                                    </mat-form-field>
                                                </div>
                                                
                                            </div>
                                            <div fxLayout="row">
                                                <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('')">Aggiungi</button>
                                            </div>
                                            <hr/>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Tac Torace')">Tac Torace</button></div>
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Rx Torace A.P. e Lat')">Rx Torace</button></div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="60%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Spirometria')">Controllo con spirometria</button></div>
                                                    <div fxFlex="40%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Prick Test')">Prick Test</button></div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Polisonnografia')">Polisonnografia</button></div>
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Prist e Rast per inalanti')">Prist e Rast</button></div>
                                            </div>
                                            <hr/>
                                            <h5>Controllo</h5>
                                            <div fxLayout="row" fxLayoutGap="5px">                                                    
                                                    <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Controllo tra 1 mese')">1 mese</button></div>
                                                    <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Controllo tra 2 mesi')">2 mesi</button></div>
                                                    <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Controllo tra 3 mesi')">3 mesi</button></div>
                                                    <div fxFlex="25%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Controllo tra 6 mesi')">6 mesi</button></div>
                                                    
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="80%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Visita di controllo con referti degli esami richiesti')">Controllo con esami richiesti</button></div></div>
                                            <hr/>
                                            <h5>Cardiologia</h5>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="100%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Visita Cardiologica ECG ed Eco cuore 2D color doppler')">Visita Cardiologica, ECG ed Ecocolordoppler</button></div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                <div fxFlex="100%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Eco cuore 2D color doppler')">Ecocolordoppler</button></div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="5px">
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Test da Sforzo su ciclorgometro')">Test da Sforzo</button></div>
                                                    <div fxFlex="50%"><button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button class='mat-vpnp' (click)="AddTratt('Holter 24h')">Holter 24h</button></div>
                                                </div>
                                        </div>
                                    </div>
                            </div>-->
                        </div>
                    </mat-tab>
                    <mat-tab label="Prescrizione FKT">
                        <br/><br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="100%">
                                        <mat-checkbox [(ngModel)]="prpn.si1" name="prpn.si1"></mat-checkbox> Aggiungi Ricetta n°1 
                                </div>
                        </div><br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="80%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Prescrizione" [(ngModel)]="prpn.prescrizione1" name="prpn.prescrizione1">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="QTA" [(ngModel)]="prpn.quantita1" name="prpn.quantita1">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="NOTA" [(ngModel)]="prpn.nota1" name="prpn.nota1">
                                    </mat-form-field>
                            </div>
                        </div>
                        <br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="100%">
                                        <mat-checkbox [(ngModel)]="prpn.si2" name="prpn.si2"></mat-checkbox> Aggiungi Ricetta n°2 
                                </div>
                        </div><br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="80%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Prescrizione" [(ngModel)]="prpn.prescrizione2" name="prpn.prescrizione2">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="QTA" [(ngModel)]="prpn.quantita2" name="prpn.quantita2">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="NOTA" [(ngModel)]="prpn.nota2" name="prpn.nota2">
                                    </mat-form-field>
                            </div>
                        </div>
                        <br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="100%">
                                        <mat-checkbox [(ngModel)]="prpn.si3" name="prpn.si3"></mat-checkbox> Aggiungi Ricetta n°3 
                                </div>
                        </div><br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="80%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Prescrizione" [(ngModel)]="prpn.prescrizione3" name="prpn.prescrizione3">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="QTA" [(ngModel)]="prpn.quantita3" name="prpn.quantita3">
                                    </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="NOTA" [(ngModel)]="prpn.nota3" name="prpn.nota3">
                                    </mat-form-field>
                            </div>
                        </div>
                        <br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="100%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Prescrizione Farmaceutica" [(ngModel)]="prpn.prescrizione_farmaceutica" name="prpn.prescrizione_farmaceutica">
                                    </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="100%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Altre prescrizioni" [(ngModel)]="prpn.altre_prescrizioni" name="prpn.altre_prescrizioni">
                                    </mat-form-field>
                                </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="100%">
                                        <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Diagnosi" [(ngModel)]="prpn.diagnosi" name="prpn.diagnosi">
                                        </mat-form-field>
                                </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                                <div fxFlex="10%">
                                        <mat-form-field fxFlexFill>
                                        <input matInput placeholder="N° di Cicli" [(ngModel)]="prpn.cicli" name="prpn.cicli">
                                        </mat-form-field>
                                </div>
                                <div fxFlex="90%">
                                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('PRPN')" class="bottom-right">Salva e Stampa</button>
                                </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Prick Test">
                        <br/><br/>
                        <div fxLayout="row" fxLayout.xs="columns" fxLayoutGap="5px">
                            <div fxFlex="10%" style="text-align: right;"><b>Seleziona set di allergeni:</b></div>
                            <div fxFlex="10%">
                                <mat-select [(ngModel)]="set_pr" name="set_pr">                            
                                    <mat-option value=1>1 - utilizzato fino al 2020</mat-option>
                                    <mat-option value=2>2 - utilizzato dal 2022</mat-option>
                                </mat-select>
    
                            </div>
                        </div>
                            
                        <div fxLayout="row" fxLayout.xs="columns" fxLayoutGap="5px">
                            <div fxFlex="50%" fxFlex.xs="100%">
                                <h3>INALANTI</h3>
                                <div *ngFor="let el of prick.pt_elements">
                                    <span *ngIf="el.group==1 && el.set==set_pr"><span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,0)"><mat-icon style="vertical-align: middle;">cancel</mat-icon></span> {{el.display_name}} <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,1)"><span *ngIf="el.value>=1" style="color: green">+</span><span *ngIf="!el.value || el.value<1" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,2)"><span *ngIf="el.value>=2" style="color: green">+</span><span *ngIf="!el.value || el.value<2" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,3)"><span *ngIf="el.value>=3" style="color: green">+</span><span *ngIf="!el.value || el.value<3" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,4)"><span *ngIf="el.value>=4" style="color: green">+</span><span *ngIf="!el.value || el.value<4" style="color: grey">-</span></span></span>
                                </div>
                            </div>
                            <div fxFlex="50%" fxFlex.xs="100%">
                                <h3>ALIMENTI</h3>
                                <div *ngFor="let el of prick.pt_elements">
                                    <span *ngIf="el.group==2 && el.set==set_pr"><span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,0)"><mat-icon style="vertical-align: middle;">cancel</mat-icon></span> {{el.display_name}} <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,1)"><span *ngIf="el.value>=1" style="color: green">+</span><span *ngIf="!el.value || el.value<1" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,2)"><span *ngIf="el.value>=2" style="color: green">+</span><span *ngIf="!el.value || el.value<2" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,3)"><span *ngIf="el.value>=3" style="color: green">+</span><span *ngIf="!el.value || el.value<3" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP(el.name,4)"><span *ngIf="el.value>=4" style="color: green">+</span><span *ngIf="!el.value || el.value<4" style="color: grey">-</span></span></span>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="100%">
                                <div>
                                    <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('negativo',0)"><mat-icon style="vertical-align: middle;">cancel</mat-icon></span> Controllo negativo <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('negativo',1)"><span *ngIf="prick.negativo>=1" style="color: green">+</span><span *ngIf="!prick.negativo || prick.negativo<1" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('negativo',2)"><span *ngIf="prick.negativo>=2" style="color: green">+</span><span *ngIf="!prick.negativo || prick.negativo<2" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('negativo',3)"><span *ngIf="prick.negativo>=3" style="color: green">+</span><span *ngIf="!prick.negativo || prick.negativo<3" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('negativo',4)"><span *ngIf="prick.negativo>=4" style="color: green">+</span><span *ngIf="!prick.negativo || prick.negativo<4" style="color: grey">-</span></span>
                                </div>
                                <div>
                                    <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('positivo',0)"><mat-icon style="vertical-align: middle;">cancel</mat-icon></span> Controllo positivo <span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('positivo',1)"><span *ngIf="prick.positivo>=1" style="color: green">+</span><span *ngIf="!prick.positivo || prick.positivo<1" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('positivo',2)"><span *ngIf="prick.positivo>=2" style="color: green">+</span><span *ngIf="!prick.positivo || prick.positivo<2" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('positivo',3)"><span *ngIf="prick.positivo>=3" style="color: green">+</span><span *ngIf="!prick.positivo || prick.positivo<3" style="color: grey">-</span></span>&nbsp;<span style="cursor: pointer; font-size: 200%; vertical-align: middle" (click)="ChangeP('positivo',4)"><span *ngIf="prick.positivo>=4" style="color: green">+</span><span *ngIf="!prick.positivo || prick.positivo<4" style="color: grey">-</span></span>
                                </div>
                            </div>
                        </div><br/>
                        <div fxLayout="row">
                            <div fxFlex="100%">
                                    <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Conclusioni diagnostiche" [(ngModel)]="prick.conclusioni" name="prick.conclusioni">
                                    </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="100%">
                                <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('PrickTest')" class="bottom-right">Salva e Stampa</button>
                        </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Test del Cammino">
                        <br>
                        <h2 style="text-align: center;">TEST DEL CAMMINO</h2>
                        <div fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="25%">
                                Sesso: <mat-select [(ngModel)]="testC.sesso" name="testC.sesso">
                                    <mat-option value="M">M</mat-option>
                                    <mat-option value="F">F</mat-option>
                                </mat-select>
                            </div>
                            <div fxFlex="25%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Altezza (cm)" [(ngModel)]="testC.altezza" name="testC.altezza">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Peso (kg)" [(ngModel)]="testC.peso" name="testC.peso">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Fi O2(%)" [(ngModel)]="testC.fi" name="testC.fi">
                                </mat-form-field>
                            </div>
                            <div>
                                <b>Indice di Massa Corporea (BMI kg/m2) (bpm): </b><i>{{(testC.peso*10000)/(testC.altezza*testC.altezza)}}</i>
                            </div>
                            <div>
                                <b>Frequenza Cardiaca Max (bpm): </b><i>{{208-(ecg_sforzo.eta * 0.7)}}</i>
                            </div>
                            <div>
                                <b>Percorrenza Teorica (m): </b><i *ngIf="testC.sesso=='M'">{{(7.57*testC.altezza)-(5.02*ecg_sforzo.eta)-(1.76*testC.peso)-309}}</i><i *ngIf="testC.sesso=='F'">{{(2.11*testC.altezza)-(5.88*ecg_sforzo.eta)-(2.29*testC.peso)+667}}</i>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <h4>PRE</h4>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="SAT HB-O2 %" [(ngModel)]="testC.sat_pre" name="testC.sat_pre">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="FREQUENZA CARDIACA (bpm)" [(ngModel)]="testC.bpm_pre" name="testC.bpm_pre">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="BORG (range 1-10)" [(ngModel)]="testC.borg_pre" name="testC.borg_pre">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <h4>POST</h4>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="SAT HB-O2 %" [(ngModel)]="testC.sat_post" name="testC.sat_post">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="FREQUENZA CARDIACA (bpm)" [(ngModel)]="testC.bpm_post" name="testC.bpm_post">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="BORG (range 1-10)" [(ngModel)]="testC.borg_post" name="testC.borg_post">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <b>% Frequenza Cardiaca raggiunta (bpm): </b><i>{{(100*testC.bpm_post)/(208-(ecg_sforzo.eta * 0.7))}}</i>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px">
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Percorrenza misurata" [(ngModel)]="testC.percorrenza_misurata" name="testC.percorrenza_misurata">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Tempo di recupero" [(ngModel)]="testC.recupero" name="testC.recupero">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="100%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Note e Conclusioni" [(ngModel)]="testC.note" name="testC.note">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('TestCammino')" class="bottom-right">Salva e Stampa</button>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-tab>
            <mat-tab label="Fisiatria" *ngIf="access_level==1 || access_level==9">
                <mat-tab-group [selectedIndex]="selectFisiatria">
                    <mat-tab label="P.R.I. e Scheda">
                    <br/><br/>
                    <div flexLayout="row">
                        <div fxFlex="100%">
                            <mat-form-field fxFlexFill>
                                <input matInput placeholder="Diagnosi" required [(ngModel)]="scheda.diagnosi" name="scheda.diagnosi">
                            </mat-form-field>
                        </div>
                    </div>
                    <div flexLayout="row">
                        <div fxFlex="100%">
                            <mat-form-field fxFlexFill>
                                <input matInput placeholder="Note Operative per i Terapisti" [(ngModel)]="scheda.note_operative" name="scheda.note_operative">
                            </mat-form-field>
                        </div>
                    </div>
                    <div flexLayout="row">
                        <div fxFlex="20%">
                            <mat-select (selectionChange)="AggiungiNote($event)">
                                <mat-option *ngFor="let terapia_ of terapie_asl" [(value)]="terapia_.descrizione">{{terapia_.descrizione}}</mat-option>
                                </mat-select>
                        </div>
                        <div fxFlex="80%">
                            <mat-form-field fxFlexFill>
                                <input matInput placeholder="Note per segreteria" [(ngModel)]="scheda.note" name="scheda.note">
                            </mat-form-field>
                        </div>
                    </div>
                    <h3>LEA <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-mini-fab color="accent" (click)="aggiungi_riga_terapia('lea')"><mat-icon>add</mat-icon></button></h3>
                    <div flexLayout="row" *ngFor="let terapy of scheda.terapie_asl; let i=index; trackBy: trackByIndex">
                            <div fxFlex="15%">
                                    Aggiungere in scheda?<br/><mat-checkbox [(ngModel)]="scheda.terapie_asl[i].in_scheda" name="scheda.terapie_asl.in_scheda+{{i}}"></mat-checkbox>
                            </div>
                        <div fxFlex="30%">
                        <mat-form-field>
                            <mat-select (selectionChange)="AggiornaArray('lea',i)" [(ngModel)]="scheda.terapie_asl[i].id_terapia" name="scheda.terapie_asl.id_terapia+{{i}}">
                            <mat-option *ngFor="let terapia_asl of terapie_asl" [(value)]="terapia_asl.idterapia">{{terapia_asl.descrizione}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        <div fxFlex="30%">
                            <mat-form-field>
                            <mat-select (selectionChange)="AggiornaSegmento('lea',i)" [(ngModel)]="scheda.terapie_asl[i].id_segmento" name="scheda.terapie_asl.id_segmento+{{i}}">
                                <mat-option *ngFor="let segmento of segmenti" [(value)]="segmento.IdSegmento">{{segmento.descrizione}}</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20%">
                            <mat-form-field>
                            <mat-select class="mini-select" [(ngModel)]="scheda.terapie_asl[i].quantita_prescritta" name="scheda.terapie_asl.quantita_prescritta+{{i}}">
                                <mat-option value="1">1</mat-option>
                                <mat-option value="2">2</mat-option>
                                <mat-option value="3">3</mat-option>
                                <mat-option value="4">4</mat-option>
                                <mat-option value="5">5</mat-option>
                                <mat-option value="6">6</mat-option>
                                <mat-option value="7">7</mat-option>
                                <mat-option value="8">8</mat-option>
                                <mat-option value="9">9</mat-option>
                                <mat-option value="10">10</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="5%">
                            <button mat-mini-fab (click)="Rimuoviterapia('lea',i)"><mat-icon color="warn">cancel</mat-icon></button>
                        </div>
                    </div>
                    <h3>LIA <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-mini-fab color="accent" (click)="aggiungi_riga_terapia('lia')"><mat-icon>add</mat-icon></button></h3>
                    <div flexLayout="row" *ngFor="let terapy of scheda.terapie; let i=index; trackBy: trackByIndex">
                        <div fxFlex="15%">
                                Aggiungere in scheda?<br/><mat-checkbox [(ngModel)]="scheda.terapie[i].in_scheda" name="scheda.terapie.in_scheda+{{i}}"></mat-checkbox>
                        </div>
                        <div fxFlex="30%">
                        <mat-form-field>
                            <mat-select (selectionChange)="AggiornaArray('lia',i)" [(ngModel)]="scheda.terapie[i].id_terapia" name="scheda.terapie.id_terapia+{{i}}">
                            <mat-option *ngFor="let terapia of terapies" [(value)]="terapia.idterapia">{{terapia.descrizione}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        <div fxFlex="30%">
                            <mat-form-field>
                            <mat-select (selectionChange)="AggiornaSegmento('lia',i)" [(ngModel)]="scheda.terapie[i].id_segmento" name="scheda.terapie.id_segmento+{{i}}">
                                <mat-option *ngFor="let segmento of segmenti" [(value)]="segmento.IdSegmento">{{segmento.descrizione}}</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="20%">
                            <mat-form-field>
                            <mat-select class="mini-select" [(ngModel)]="scheda.terapie[i].quantita_prescritta" name="scheda.terapie.quantita_prescritta+{{i}}">
                                <mat-option value="1">1</mat-option>
                                <mat-option value="2">2</mat-option>
                                <mat-option value="3">3</mat-option>
                                <mat-option value="4">4</mat-option>
                                <mat-option value="5">5</mat-option>
                                <mat-option value="6">6</mat-option>
                                <mat-option value="7">7</mat-option>
                                <mat-option value="8">8</mat-option>
                                <mat-option value="9">9</mat-option>
                                <mat-option value="10">10</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="5%">
                            <button mat-mini-fab (click)="Rimuoviterapia('lia',i)"><mat-icon color="warn">cancel</mat-icon></button>
                        </div>
                    </div>
                    <br/>
                    <div flexLayout="row">
                        <div fxFlex="100%">
                            <mat-form-field fxFlexFill>
                                <textarea rows="5" matInput placeholder="Altre prescrizioni" [(ngModel)]="scheda.altro" name="scheda.altro"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <br/><br/>
                    Creare Scheda?<mat-checkbox [(ngModel)]="creare_scheda" name="creare_scheda"></mat-checkbox><br/><br/>
                    <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('PRI')" class="bottom-right">Salva e Stampa</button>
                </mat-tab>
                    <mat-tab label="Elettromiografia">

                    </mat-tab>
                    <mat-tab label="Esame Baropodometrico">
                        <br/><br/>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                    Il test stabilometrico&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.stabilometrico" name="bpe.stabilometrico"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        La baropodometria statica&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.statica" name="bpe.statica"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        La baropodometria dinamica, associata all'analisi cinematica,&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.dinamica" name="bpe.dinamica"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        La Biometria 3D&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.biometria" name="bpe.biometria"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        Il test posturografico&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.posturografico" name="bpe.posturografico"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        Rispetto al precedente esame del&nbsp;
                                    <mat-form-field fxFlexFill>
                                        <textarea matInput [(ngModel)]="bpe.precedente" name="bpe.precedente"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div flexLayout="row">
                                <div fxFlex="100%">
                                        Si consiglia:&nbsp;
                                    <mat-form-field fxFlexFill>
                                       <textarea matInput [(ngModel)]="bpe.consigli" name="bpe.consigli"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('BPE')" class="bottom-right">Salva e Stampa</button>
                    </mat-tab>
                    <mat-tab label="Esito Terapia Inail">
                        <div flexLayout="row">
                            <div fxFlex="100%">
                                <mat-form-field fxFlexFill>
                                    <textarea rows="7" matInput [(ngModel)]="esitoinail" name="esitoinail"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <br/><br/>
                        <button [ngClass]="{'red': selectBranca === 0, 'green' : selectBranca === 1, 'blue' : selectBranca === 2 }" mat-raised-button color="primary" (click)="Salva('EsitoInail')" class="bottom-right">Salva e Stampa</button>
                    </mat-tab>
                </mat-tab-group>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
</mat-sidenav-content>
  
</mat-sidenav-container>