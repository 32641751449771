<mat-card>
  <!--INTESTAZIONI-->
  <div id="intestazioni" style="display:none">
  </div>
  <!--INTESTAZIONI-->
  <mat-card-title>
    Stampa Certificati
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab *ngIf="access_level<3" label="Medicina 2000">
        <!--<mat-card *ngIf="acl==1">
          <mat-card-title>Crea Documento su Carta Intestata</mat-card-title>
          <mat-card-content>
              <div [froalaEditor] [(froalaModel)]="editor_m2"></div>
          </mat-card-content>
          <div style="display: none" id="Froala_m2" [froalaView]="editor_m2"></div>
          <mat-card-content><button mat-raised-button color="primary" (click)="service.print('Froala_m2','Pneumologia',1,m2)">Stampa</button></mat-card-content>
        </mat-card>-->
          
          <mat-card *ngIf="acl==1">
              <mat-card-title class="green">Carta Intestata in bianco <button mat-raised-button color="primary" (click)="service.print('intestazioni','','','','','','Pneumologia',1,m2)">Stampa</button></mat-card-title>
          </mat-card>
          <mat-card>
              <mat-card-title class="green">Certificato di presenza</mat-card-title>
              <mat-card-content>
                  <div fxLayout="row">              
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nome e Cognome" [(ngModel)]="paziente.nome" name="paziente.nome">
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Luogo di nascita" [(ngModel)]="paziente.luogo_nascita" name="paziente.luogo_nascita">
                      </mat-form-field>
                    </div>
                    <div fxFlex="34%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita" name="paziente.data_nascita">
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nelle ore" [(ngModel)]="paziente.ore" name="paziente.ore">
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                        <mat-select placeholder="Prestazione" [(ngModel)]="paziente.prestazione" name="paziente.prestazione">
                                <mat-option value="il trattamento di Fisiokinesiterapia prescritto">Fisiokinesiterapia</mat-option>
                                <mat-option value="la visita specialistica prescritta">Visita specialistica</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">
                      <mat-form-field fxFlexFill>
                        <input matInput type="date" placeholder="Data certificato" [(ngModel)]="paziente.data">
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex="13%">
                      FACOLTATIVO:<br/>(lasciare vuoti i campi se non serve)
                    </div>              
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Accompagnato da (Nome e Cognome)" [(ngModel)]="paziente.accompagnatore" name="paziente.accompagnatore">
                      </mat-form-field>
                    </div>
                    <div fxFlex="20%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Tipo di documento" [(ngModel)]="paziente.tipo_documento" name="paziente.tipo_documento">
                      </mat-form-field>
                    </div>
                    <div fxFlex="34%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nr. Documento" [(ngModel)]="paziente.nr_documento" name="paziente.nr_documento">
                      </mat-form-field>
                    </div>
                  </div>
                  <button mat-raised-button color="primary" (click)="service.print('AttestazionePresenze','',paziente.data,paziente,'','','Pneumologia',1,m2)">STAMPA</button>
              </mat-card-content>
            </mat-card>
      </mat-tab>
      <mat-tab *ngIf="access_level<3" label="Centro Scoliosi">
         <!-- <mat-card *ngIf="acl==1">
              <mat-card-title>Crea Documento su Carta Intestata</mat-card-title>
              <mat-card-content>
                  <div [froalaEditor] [(froalaModel)]="editor_cs"></div>
              </mat-card-content>
              <div style="display: none" id="Froala_cs" [froalaView]="editor_cs"></div>
              <mat-card-content><button mat-raised-button color="primary" (click)="service.print('Froala_cs','Fisiatria',1,cs)">Stampa</button></mat-card-content>
            </mat-card>-->
        <mat-card *ngIf="acl==1">
          <mat-card-title class="blue">Carta Intestata in bianco <button mat-raised-button color="primary" (click)="service.print('intestazioni','','','','','','Fisiatria',1,cs)">Stampa</button></mat-card-title>
          <br/>
          <mat-card-title class="blue">Istruzioni Plantare <button mat-raised-button color="primary" (click)="service.print('IstruzioniPlantare','','','','','','Fisiatria',1,cs)">Stampa</button></mat-card-title>
          <br/>
          <mat-card-title class="blue">Attestazione presenze INAIL <button mat-raised-button color="primary" (click)="service.print('AttestazioneInail','','','','','','Fisiatria',1,cs)">STAMPA</button></mat-card-title>
        </mat-card>
        <mat-card>
          <mat-card-title class="blue">Certificato di presenza</mat-card-title>
          <mat-card-content>
              <div fxLayout="row">              
                <div fxFlex="33%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Nome e Cognome" [(ngModel)]="paziente.nome" name="paziente.nome">
                  </mat-form-field>
                </div>
                <div fxFlex="33%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Luogo di nascita" [(ngModel)]="paziente.luogo_nascita" name="paziente.luogo_nascita">
                  </mat-form-field>
                </div>
                <div fxFlex="34%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita" name="paziente.data_nascita">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="33%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Nelle ore" [(ngModel)]="paziente.ore" name="paziente.ore">
                  </mat-form-field>
                </div>
                <div fxFlex="33%">                          
                  <mat-form-field fxFlexFill>
                    <mat-select placeholder="Prestazione" [(ngModel)]="paziente.prestazione" name="paziente.prestazione">
                            <mat-option value="il trattamento di Fisiokinesiterapia prescritto">Fisiokinesiterapia</mat-option>
                            <mat-option value="la visita specialistica prescritta">Visita specialistica</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="33%">
                  <mat-form-field fxFlexFill>
                    <input matInput type="date" placeholder="Data certificato" [(ngModel)]="paziente.data">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row">
                <div fxFlex="13%">
                  FACOLTATIVO:<br/>(lasciare vuoti i campi se non serve)
                </div>                
                <div fxFlex="33%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Accompagnato da (Nome e Cognome)" [(ngModel)]="paziente.accompagnatore" name="paziente.accompagnatore">
                  </mat-form-field>
                </div>
                <div fxFlex="20%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Tipo di documento" [(ngModel)]="paziente.tipo_documento" name="paziente.tipo_documento">
                  </mat-form-field>
                </div>
                <div fxFlex="34%">                          
                  <mat-form-field fxFlexFill>
                  <input matInput placeholder="Nr. Documento" [(ngModel)]="paziente.nr_documento" name="paziente.nr_documento">
                  </mat-form-field>
                </div>
              </div>
              <button mat-raised-button color="primary" (click)="service.print('AttestazionePresenze','',paziente.data,paziente,'','','Fisiatria',1,cs)">STAMPA</button>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Centro Diagnostico Cardiopolmonare">
          <!--<mat-card *ngIf="acl==1">
              <mat-card-title>Crea Documento su Carta Intestata</mat-card-title>
              <mat-card-content>
                  <div [froalaEditor] [(froalaModel)]="editor_cdcp"></div>
              </mat-card-content>
              <div style="display: none" id="Froala_cdcp" [froalaView]="editor_cdcp"></div>
              <mat-card-content><button mat-raised-button color="primary" (click)="service.print('Froala_cdcp','Cardiologia',1,cdcp)">Stampa</button></mat-card-content>
            </mat-card>-->
          <mat-card *ngIf="acl==1">
              <mat-card-title class="red">Carta Intestata in bianco <button mat-raised-button color="primary" (click)="service.print('intestazioni','','','','','','Cardiologia',1,cdcp)">Stampa</button></mat-card-title>
          </mat-card>
          <mat-card>
              <mat-card-title class="red">Certificato di presenza</mat-card-title>
              <mat-card-content>
                  <div fxLayout="row">              
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nome e Cognome" [(ngModel)]="paziente.nome" name="paziente.nome">
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Luogo di nascita" [(ngModel)]="paziente.luogo_nascita" name="paziente.luogo_nascita">
                      </mat-form-field>
                    </div>
                    <div fxFlex="34%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita" name="paziente.data_nascita">
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nelle ore" [(ngModel)]="paziente.ore" name="paziente.ore">
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                        <mat-select placeholder="Prestazione" [(ngModel)]="paziente.prestazione" name="paziente.prestazione">
                                <mat-option value="montaggio/smontaggio di un dispositivo Holter">Holter</mat-option>
                                <mat-option value="la visita specialistica prescritta">Visita specialistica</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="33%">
                      <mat-form-field fxFlexFill>
                      <input matInput type="date" placeholder="Data certificato" [(ngModel)]="paziente.data">
                    </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxFlex="13%">
                      FACOLTATIVO:<br/>(lasciare vuoti i campi se non serve)
                    </div>                
                    <div fxFlex="33%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Accompagnato da (Nome e Cognome)" [(ngModel)]="paziente.accompagnatore" name="paziente.accompagnatore">
                      </mat-form-field>
                    </div>
                    <div fxFlex="20%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Tipo di documento" [(ngModel)]="paziente.tipo_documento" name="paziente.tipo_documento">
                      </mat-form-field>
                    </div>
                    <div fxFlex="34%">                          
                      <mat-form-field fxFlexFill>
                      <input matInput placeholder="Nr. Documento" [(ngModel)]="paziente.nr_documento" name="paziente.nr_documento">
                      </mat-form-field>
                    </div>
                  </div>
                  <button mat-raised-button color="primary" (click)="service.print('AttestazionePresenze','',paziente.data,paziente,'','','Cardiologia',1,cdcp)">STAMPA</button>
              </mat-card-content>
            </mat-card>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>