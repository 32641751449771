<mat-card>
    <mat-card-title>Riepilogo Presenze</mat-card-title>
    <mat-card-content>
      <div flexLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
          <div fxFlex="30%" >
              Seleziona l'Operatore:  
            <mat-form-field fxFlexFill>
              <mat-select name="username" [(ngModel)]="form_presenza.username">
                <mat-option *ngFor="let name of username" value="{{name.username}}">
                  {{ name.username }}
                </mat-option>
              </mat-select>
              </mat-form-field>
          </div>
          <div fxFlex="30%">
            &nbsp;<br/>
              <mat-form-field fxFlexFill>
                  <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="form_presenza.data_in">
                </mat-form-field>
          </div>
          <div fxFlex="30%">
              &nbsp;<br/>
              <mat-form-field fxFlexFill>
                  <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="form_presenza.data_fin">
              </mat-form-field>
          </div>
          <div fxFlex="10%">
            <button mat-raised-button color="primary" (click)="Cerca()">INVIO</button>
          </div>
      </div>
      <br/>
      <div flexLayout="row">
          <div fxFlex="100%">
              Remunerazione oraria: {{remunerazione}}€, Rivalsa INPS: {{rivalsa}}%, Ritenuta d'acconto: {{ritenuta}}%
          </div>
      </div>
      <br/>
      <br/>
      <div flexLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
          <div fxFlex="40%" fxFlex.xs="100%">
            Aggiungi ulteriori ore di lavoro non registrate nel database: 
          </div>
          <div fxFlex="30%" fxFlex.xs="100%">
            <mat-form-field fxFlexFill>
              <input matInput placeholder="Ore" min="0" type="number" [(ngModel)]="ore_agg" step="1">
          </mat-form-field>
          </div>
          <div fxFlex="30%" fxFlex.xs="100%">
              <mat-form-field fxFlexFill>
                <input matInput placeholder="Minuti" min="0" max="59" type="number" [(ngModel)]="minuti_agg" step="1">
            </mat-form-field>
          </div>
      </div>
      <div flexLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxFlex="30%" fxFlex.xs="100%">
          <mat-form-field fxFlexFill>
            <input matInput placeholder="Lordo da detrarre per prestazioni private" min="0" type="number" [(ngModel)]="lordo_meno">
        </mat-form-field>
        </div>
        <div fxFlex="30%" fxFlex.xs="100%">
          <mat-form-field fxFlexFill>
            <input matInput placeholder="Lordo da aggiungere per ulteriori prestazioni" min="0" type="number" [(ngModel)]="lordo_piu">
        </mat-form-field>
        </div>
        <div fxFlex="10%">
          <button mat-raised-button color="warn" (click)="Cerca()">Aggiorna Lordo</button>
        </div>
      </div>
      <!--TABELLE RIASSUNTIVE-->      

      <div id="totale_orario" fxLayout="row" *ngIf="totale_preciso>0">
        <div fxFlex="50%">
            <table class="table_vf" border="1">
                <tr>
                  <th>
                    TOTALE ORE LAVORO {{form_presenza.username}} dal {{form_presenza.data_in | date: 'dd/MM/yyyy'}} al {{form_presenza.data_fin | date: 'dd/MM/yyyy'}}
                  </th>
                  <th>
                    {{totale_orario}} <span *ngIf="ore_agg || minuti_agg">+ {{ore_agg}}:{{minuti_agg}}:00</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    Totale Lordo
                  </th>
                  <td>
                    {{somma_precisa}}€
                  </td>
                </tr>
                <tr *ngIf="rivalsa>0">
                  <th>
                      Rivalsa INPS al {{rivalsa}}%
                    </th>
                    <td>
                      {{rivalsa_precisa}}€
                    </td>
                </tr>
                <tr *ngIf="ritenuta>0">
                  <th>
                      Ritenuta al {{ritenuta}}%
                    </th>
                    <td>
                      {{ritenuta_precisa}}€
                    </td>
                </tr>
                <tr *ngIf="marca_bollo_orario>0">
                  <th>
                     Marca da Bollo
                    </th>
                    <td>
                      2€
                    </td>
                </tr>
                <tr>
                  <th>
                    TOTALE A PAGARE
                  </th>
                  <td>
                    {{(totale_preciso)}}€
                  </td>
                </tr>
              </table>
        </div>
        
      </div>
      <button *ngIf="totale_preciso>0" mat-raised-button color="primary" (click)="service.print('totale_orario','','','','','','',1,'')">STAMPA TOTALE ORARIO</button>
      <div id="TOTALE_PRESTAZIONI_CARDIOLOGIA" *ngIf="totale_prestazioni && totale_lordo>0">
        <h5 >Riepilogo Prestazioni - Operatore: {{form_presenza.username}} <br/> dal {{form_presenza.data_in | date:  'dd/MM/yyyy'}} al {{form_presenza.data_fin | date:  'dd/MM/yyyy'}}</h5>
        <table class="table_vf" border=1>
          <tr>
            <th>PRESTAZIONE</th>
            <th>Refertate</th>
            <th>Costo</th>
            <th>Totali</th>
          </tr>
          <tr *ngFor="let nome of array_nomi_cardio; let i=index">
            <td>{{ReturnNome(nome)}}</td>
            <td>{{totali[nome]}}</td>
            <td>{{ReturnCosto(nome)}} €</td>
            <td>{{totali[nome]*ReturnCosto(nome)}} €</td>
          </tr>
          <tr *ngIf="totale_lordo!=lordo_visual">
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo Prestazioni</th>
            <td>{{totale_lordo}} €</td>
          </tr>
          <tr *ngIf="lordo_meno>0">
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo detratto</th>
            <td> {{lordo_meno}} €</td>
          </tr>
          <tr *ngIf="lordo_piu>0">
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo aggiunto</th>
            <td> {{lordo_piu}} €</td>
          </tr>
          <tr>
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">TOTALE LORDO</th>
            <td>{{lordo_visual}} €</td>
          </tr>
          <tr *ngIf="rivalsa>0">
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Rivalsa INPS al {{rivalsa}}%</th>
            <td>{{rivalsa_inps_prestazioni}} €</td>
          </tr>
          <tr *ngIf="ritenuta>0">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Ritenuta al {{ritenuta}}%</th>
              <td> {{ritenuta_prestazioni}} €</td>
          </tr>
          <tr *ngIf="totale_netto>77.47">
            <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Marca da Bollo</th>
            <td>2€</td>
          </tr>
          <tr>
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Netto a pagare</th>
              <td> {{totale_netto_visual}} €</td>
          </tr>
        </table>
            <h5 *ngIf="prestazioni_private.length">Prestazioni private eseguite nel periodo di riferimento da detrarre</h5>
          <div *ngFor="let prestazione of prestazioni_private">
            {{prestazione.data | date: 'dd/MM/yyyy'}} - {{prestazione.paziente}} <span> - {{prestazione.prestazione}}</span>
          </div>
      </div>
        <button class="noprint" *ngIf="totale_prestazioni && totale_lordo>0" mat-raised-button color="primary" (click)="service.print('TOTALE_PRESTAZIONI_CARDIOLOGIA','','','','','','',1,'')">STAMPA TOTALE PRESTAZIONI CARDIOLOGIA</button>
      <div id="TOTALE_PRESTAZIONI_PNEUMOLOGIA" *ngIf="totale_prestazioni && pneumo_totale_lordo>0">
        <h5>Riepilogo Prestazioni - Operatore: {{form_presenza.username}} <br/> dal {{form_presenza.data_in | date:  'dd/MM/yyyy'}} al {{form_presenza.data_fin | date:  'dd/MM/yyyy'}}</h5>
        <div fxLayout="row">        
          <table class="table_vf" border=1>
            <tr>
              <th>PRESTAZIONE</th>
              <th>Refertate</th>
              <th>Costo</th>
              <th>Totali</th>
            </tr>
            <tr *ngFor="let nome of array_nomi_pneumo; let i=index">
              <td>{{ReturnNome(nome)}}</td>
              <td>{{totali[nome]}}</td>
              <td>{{ReturnCosto(nome)}} €</td>
              <td>{{totali[nome]*ReturnCosto(nome)}} €</td>
            </tr>
            <tr *ngIf="pneumo_totale_lordo!=pneumo_lordo_visual">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo Prestazioni</th>
              <td>{{pneumo_totale_lordo}} €</td>
            </tr>
            <tr *ngIf="lordo_meno>0">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo detratto</th>
              <td> {{lordo_meno}} €</td>
            </tr>
            <tr *ngIf="lordo_piu>0">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Lordo aggiunto</th>
              <td> {{lordo_piu}} €</td>
            </tr>
            <tr>
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">TOTALE LORDO</th>
              <td>{{pneumo_lordo_visual}} €</td>
            </tr>
            <tr *ngIf="rivalsa>0">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Rivalsa INPS al {{rivalsa}}%</th>
              <td>{{pneumo_rivalsa_inps_prestazioni}} €</td>
            </tr>
            <tr *ngIf="ritenuta>0">
                <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Ritenuta al {{ritenuta}}%</th>
                <td> {{pneumo_ritenuta_prestazioni}} €</td>
            </tr>
            
            <tr *ngIf="pneumo_totale_netto>77.47">
              <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Marca da Bollo</th>
              <td>2€</td>
            </tr>
            <tr>
                <th colspan="3" style="text-align:right; padding: 5px 5px 5px 5px;">Netto a pagare</th>
                <td> {{pneumo_totale_netto_visual}} €</td>
            </tr>
          </table>
          <h5 *ngIf="prestazioni_private.length">Prestazioni private eseguite nel periodo di riferimento da detrarre</h5>
          <div *ngFor="let prestazione of prestazioni_private">
            {{prestazione.data | date: 'dd/MM/yyyy'}} - {{prestazione.paziente}} <span> - {{prestazione.prestazione}}</span>
          </div>
        </div>
       
      </div>
      <button  class="noprint" *ngIf="totale_prestazioni && pneumo_totale_lordo>0" mat-raised-button color="primary" (click)="service.print('TOTALE_PRESTAZIONI_PNEUMOLOGIA','','','','','','',1,'')">STAMPA TOTALE PRESTAZIONI PNEUMOLOGIA</button>
      
      <div *ngIf="totale_prestazioni || totale_preciso>0">
        <h3>RIEPILOGHI</h3>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="50%" fxFlex.xs="100%">
            <h5>Riepilogo Orario</h5>
            <div fxLayout="column">
              <div fxFlex="100%" *ngFor="let el of elenco_presenze">
                <b>Data</b>: {{el.data | date: 'dd/MM/yyyy'}} - <b>Ingresso:</b> {{el.orario_ingresso}} - <b>Uscita:</b> {{el.orario_uscita}}
              </div>
            </div>
          </div>
          <div fxFlex="50%" fxFlex.xs="100%">
            <h5>Riepilogo Prestazioni</h5>
            <div fxLayout="column">
              <div fxFlex="100%" *ngFor="let el of elenco_visite">
                <button mat-raised-button color="primary" (click)="showInfo(el,0)">Visualizza</button> - <b>Data e ora:</b> {{el.data | date: 'dd/MM/yyyy'}}, {{el.ora}} - <b>Paziente:</b> {{el.cognome}} {{el.nome}} - <b>Tipo:</b> {{ReturnNome(el.tipo)}}
              </div>
            </div>
          </div>
        </div>
      </div>
      </mat-card-content>
    
      <!--
      
    <div fxLayout="row">
          <td-data-table  #dataTable [data]="elenco_presenze" [columns]="columns" >
            <ng-template tdDataTableTemplate="data" let-value="data" let-row="row" >
                {{ row.data | date: 'dd/MM/yyyy'}}
            </ng-template>
          </td-data-table>
      </div>
        <div fxLayout="row" *ngIf="totale_prestazioni">
          
          <div fxFlex="80%">
              <h1>Elenco prestazioni eseguite dall'operatore</h1>
            <td-data-table  #dataTable 
            [data]="filteredData" 
            [columns]="columns_visite" 
            [sortable]="true"
            [sortBy]="sortBy"
            [sortOrder]="sortOrder"
            (sortChange)="sort($event)"
            >
              <ng-template tdDataTableTemplate="data" let-value="data" let-row="row" >
                  {{ row.data | date: 'dd/MM/yyyy'}}
              </ng-template>
              <ng-template tdDataTableTemplate="idpaziente" let-value="idpaziente" let-row="row">
                <a [routerLink]="['/archivio',row.idpaziente]"><mat-icon>search</mat-icon></a>
              </ng-template>
              <ng-template tdDataTableTemplate="cognome" let-value="cognome" let-row="row" >
                {{ row.cognome }} {{row.nome}}
            </ng-template>
            </td-data-table>
          </div>
          </div>
          <div id="Intestazione" style="display:none"><h2>Riepilogo prestazioni {{form_presenza.username}}</h2><h4>dal {{form_presenza.data_in | date: 'dd/MM/yyyy'}} al {{form_presenza.data_fin | date: 'dd/MM/yyyy'}}</h4></div>
    </mat-card-content>-->
</mat-card>


