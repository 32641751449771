import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { version } from '../../../package.json';

import { AuthenticationService } from '../_services';

@Component({ templateUrl: 'login.component.html',
styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    breakpoint;
    versione=version;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.http.get('../assets/version.json').subscribe(data => {
            if(version!=data) location.reload(true)
        })
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.breakpoint = (window.innerWidth <= 700) ? 1 : 3;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onResize(event) {
        this.breakpoint = (event.target.innerWidth <= 700) ? 1 : 3;
      }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value.toUpperCase(), this.f.password.value.toUpperCase())
            .pipe(first())
            .subscribe(
                data => {
                    if(data==1) this.router.navigate([this.returnUrl]);
                    else if(data == 0) this.router.navigate(['/chgpsw']);
                    else this.error="Nome utente e /o password errati, oppure l'utente è disabilitato all'acesso al sistema";
                    this.loading=false;
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
