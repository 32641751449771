import { Component, OnInit, ViewChild } from '@angular/core';
import {GlobalServices} from '../_services';
//import { TdDataTableService, TdDataTableSortingOrder, ITdDataTableSortChangeEvent, ITdDataTableColumn, IPageChangeEvent } from '@covalent/core';

import {  Router,ActivatedRoute } from '@angular/router';
import {Plantari, Impostazioni } from '../_models';
import { FormControl } from '@angular/forms';
//import { timeStamp } from 'console';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-plantari',
  templateUrl: './plantari.component.html',
  styleUrls: ['./plantari.component.css']
})
export class PlantariComponent implements OnInit {
  displayedColumns: string[] = ['cognome','nome','data_nascita','data_richiesta','telefono','scarpa','acconto','spedito','actions'];
  innerWidth: any;
    hidden=false;
    array_plantari= new Array<Plantari>();
    plantare= new Plantari();
    cs= new Impostazioni;
    apparecchi;
    temp= new Temp();
    //columns: ITdDataTableColumn[];
    filteredData: any[] = this.array_plantari;    
    filteredTotal: number = this.array_plantari.length;
    searchTerm: string = '';
    today=new Date();
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 5;
    sortBy: string = 'data_richiesta';
    selectedRows: any[] = [];
    //sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
    myControl: FormControl = new FormControl();
  access_level;
  dataSource: MatTableDataSource<Plantari>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public service: GlobalServices) { }
    

  ngOnInit() {
    this.access_level=this.service.get_access_level();
    this.temp.data_in="2020-01-01";
    this.temp.data_out= new Date().toISOString().substring(0, 10);
    this.service.get_function(this.service.apiGetImpostazioni+'cs').subscribe(data=>this.cs=JSON.parse(data[0].value));
    this.service.post_function(JSON.stringify(this.temp),this.service.apiPlantari).subscribe(
      (result:any)=> 
      {
        this.array_plantari=result;
        this.dataSource=new MatTableDataSource(this.array_plantari);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  Spedito(row){
    this.service.post_function(JSON.stringify(row),this.service.apiModPlantare+row.id).subscribe(
      result=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiPlantari).subscribe(
          (result:any)=> 
          {
            this.array_plantari=result;
            this.dataSource=new MatTableDataSource(this.array_plantari);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        );
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Aggiungi(){
    this.plantare.data_richiesta=this.today;
    this.service.post_function(JSON.stringify(this.plantare),this.service.apiModPlantare).subscribe(
      result=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiPlantari).subscribe(
          (result:any)=> 
          {
            this.service.print('Plantare','',this.today,this.plantare,'','','Fisiatria',1,this.cs);
            this.array_plantari=result;
            this.dataSource=new MatTableDataSource(this.array_plantari);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        );
      }
    )

  }

  Elimina(row){
    this.service.post_function(JSON.stringify(row),this.service.apiDeletePlantare+row.id).subscribe(
      result=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiPlantari).subscribe(
          (result:any)=> 
          {
            this.array_plantari=result;
            this.dataSource=new MatTableDataSource(this.array_plantari);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        );
      }
    )
  }

  

  Cerca(){
    /*this.temp.data_in.setHours(2);
    this.temp.data_out.setHours(2);*/
    this.service.post_function(JSON.stringify(this.temp),this.service.apiPlantari).subscribe(
      (result:any)=> 
      {
        this.array_plantari=result;
        this.dataSource=new MatTableDataSource(this.array_plantari);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
  }

}

class Temp{
  data_in;
  data_out;
}

