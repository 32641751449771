import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {GlobalServices} from '../_services';
//import { TdDataTableService, TdDataTableSortingOrder, ITdDataTableSortChangeEvent, ITdDataTableColumn, IPageChangeEvent } from '@covalent/core';

import {  Router,ActivatedRoute } from '@angular/router';
import {Plantari, Impostazioni, Polisonno } from '../_models';
import { FormControl } from '@angular/forms';
//import { timeStamp } from 'console';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  note_vecchie: string;
  paziente: string;
  nuova_nota: string;
}

@Component({
  selector: 'app-polisonnografia',
  templateUrl: './polisonnografia.component.html',
  styleUrls: ['./polisonnografia.component.scss']
})
export class PolisonnografiaComponent implements OnInit {

  displayedColumns: string[] = ['cognome','nome','data_nascita','data_richiesta','telefono','indirizzo','note','data_esecuzione','data_refertazione','data_consegna','actions'];
  innerWidth: any;
    hidden=false;
    array_polisonno= new Array<Polisonno>();
    polisonno= new Polisonno();
    m2= new Impostazioni;
    apparecchi;
    temp= new Temp();
    //columns: ITdDataTableColumn[];
    filteredData: any[] = this.array_polisonno;    
    filteredTotal: number = this.array_polisonno.length;
    searchTerm: string = '';
    today=new Date();
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 5;
    sortBy: string = 'data_richiesta';
    selectedRows: any[] = [];
    //sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
    myControl: FormControl = new FormControl();
  access_level;
  dataSource: MatTableDataSource<Polisonno>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  note: any;

  constructor(public service: GlobalServices,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.access_level=this.service.get_access_level();
    this.temp.data_in="2020-01-01";
    this.temp.data_out= new Date().toISOString().substring(0, 10);
    this.service.get_function(this.service.apiGetImpostazioni+'m2').subscribe(data=>this.m2=JSON.parse(data[0].value));
    this.service.post_function(JSON.stringify(this.temp),this.service.apiPolisonno).subscribe(
      (result:any)=> 
      {
        this.array_polisonno=result;
        this.dataSource=new MatTableDataSource(this.array_polisonno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  Modificato(action,row){
    this.service.post_function(JSON.stringify(row),this.service.apiModPolisonno+action+'/'+row.id).subscribe(
      result=> 
      {
        this.Cerca();
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Aggiungi(){
    this.polisonno.data_nascita;
    this.polisonno.data_richiesta=this.today;
    this.service.post_function(JSON.stringify(this.polisonno),this.service.apiModPolisonno).subscribe(
      result=> 
      {
        this.Cerca();
      }
    )

  }

  Elimina(row){
    this.service.post_function(JSON.stringify(row),this.service.apiDeletePolisonno+row.id).subscribe(
      result=> 
      {
        this.Cerca();
      }
    )
  }

  Cerca(){
    /*this.temp.data_in.setHours(2);
    this.temp.data_out.setHours(2);*/
    this.service.post_function(JSON.stringify(this.temp),this.service.apiPolisonno).subscribe(
      (result:any)=> 
      {
        this.array_polisonno=new Array<Polisonno>();
        this.array_polisonno=result;
        this.dataSource=new MatTableDataSource(this.array_polisonno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
  }

  openDialog(x: Polisonno): void {
    const dialogRef = this.dialog.open(NotaPolisonnoDialog, {
      width: '400px',
      data: {note_vecchie: x.note, paziente:x.cognome+' '+x.nome+' '+x.data_nascita,nuova_nota:''},
    });

    dialogRef.afterClosed().subscribe(result => 
     { 
      x.note += ';' + result;
      this.Modificato('n',x)
      }
    );
  }

}

@Component({
  selector: 'nota-polisonno-dialog',
  templateUrl: 'nota.html',
})
export class NotaPolisonnoDialog {
  constructor(
    public dialogRef: MatDialogRef<NotaPolisonnoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

class Temp{
  data_in;
  data_out;
}