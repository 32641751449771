import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import {GlobalServices} from '../_services';
//import { TdDataTableService, TdDataTableSortingOrder, ITdDataTableSortChangeEvent, ITdDataTableColumn, IPageChangeEvent } from '@covalent/core';

import {  Router,ActivatedRoute } from '@angular/router';
import { Holter, Impostazioni } from '../_models';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-holter',
  templateUrl: './holter.component.html',
  styleUrls: ['./holter.component.css']
})
export class HolterComponent implements OnInit {

  constructor(public service: GlobalServices, private _snackBar: MatSnackBar
    ) { }

    innerWidth: any;
    hidden=false;
    array_holter= new Array<Holter>();
    holter= new Holter();
    cdcp= new Impostazioni;
    apparecchi;
    temp= new Temp();
    dataSource: MatTableDataSource<Holter>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

    filteredData: any[] = this.array_holter;    
    filteredTotal: number = this.array_holter.length;
    today=new Date();
    selectedRows: any[] = [];
    displayedColumns: string[] = ['cognome','nome','data_nascita','tipo','asl','serial_number','data_montaggio','stato','password','actions'];

  ngOnInit() {
    this.temp.data_in="2019-01-01";
    this.temp.data_out= new Date().toISOString().substring(0, 10);
    this.service.get_function(this.service.apiGetImpostazioni+'cdcp').subscribe(data=>this.cdcp=JSON.parse(data[0].value));
    this.service.post_function(JSON.stringify(this.temp),this.service.apiHolter).subscribe(
      (result: any)=> 
      {
        this.array_holter=result;
        this.dataSource=new MatTableDataSource(this.array_holter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open("Password copiata negli appunti",'Ok',{duration: 1500});
    
  }


  Apparecchi(){
    var value;
    if(this.holter.tipo==0) value="HolterECG";
    else value="HolterPressorio"
    this.service.get_function(this.service.apigetApparecchi+value+'/cdcp').subscribe(
      (result: any)=> 
      {
        this.apparecchi=result;
      }
    )
  }

  Ripeti(row){
    this.service.post_function(JSON.stringify(row),this.service.apiModHolter+row.id).subscribe(
      (result: any)=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiHolter).subscribe(
          (result: any)=> 
          {
            this.array_holter=result;
            this.dataSource=new MatTableDataSource(this.array_holter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
          }
        );
      }
    )
  }

  Elimina(row){
    this.service.post_function(JSON.stringify(row),this.service.apiDeleteHolter+row.id).subscribe(
      (result: any)=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiHolter).subscribe(
          (result: any)=> 
          {
            this.array_holter=result;
            this.dataSource=new MatTableDataSource(this.array_holter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
          }
        );
      }
    )
  }

  Aggiungi(){
    this.holter.password=this.makeid();
    this.service.post_function(JSON.stringify(this.holter),this.service.apiModHolter).subscribe(
      (result: any)=> 
      {
        this.service.post_function(JSON.stringify(this.temp),this.service.apiHolter).subscribe(
          (result: any)=> 
          {
            this.service.print('Holter','',this.today,this.holter,'','','Cardiologia',1,this.cdcp);
            this.array_holter=result;
            this.dataSource=new MatTableDataSource(this.array_holter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
          }
        );
      }
    )

  }


  makeid() {
    var text = ""; 
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  
    for (var i = 0; i < 7; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  

  Cerca(){
    this.service.post_function(JSON.stringify(this.temp),this.service.apiHolter).subscribe(
      (result: any)=> 
      {
        this.array_holter=result;
        this.dataSource=new MatTableDataSource(this.array_holter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

class Temp{
  data_in;
  data_out;
}
