import { Component, OnInit, ChangeDetectionStrategy,ChangeDetectorRef,ViewChild} from '@angular/core';
import{GlobalServices} from '../_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Howl, Howler} from 'howler';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Pazienti_schede, Pazienti_ordine, Form_Presenze, Privato, Segnalazioni, Utenti, TipiVisite, Pazienti, Oggetto_Esegui_terapia, Terapia_eseguita, Polisonno } from '../_models';
import {  Router,ActivatedRoute,Event,NavigationStart,NavigationEnd,NavigationError } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { NoteDirettoreComponent } from '../note-direttore/note-direttore.component';
import { Ip_Timbri } from '../timbri/timbri.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { isThisSecond } from 'date-fns';
import { ElencoPazientiComponent } from '../archivio-pazienti/elenco-pazienti/elenco-pazienti.component';

//import { Ip_Timbri } from '../timbri/timbri.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  cf;
  array_polisonno=new Array<Polisonno>();
  pazienti= new Array<any>();
  utenti=new Array<any>();
  privato= new Privato();
  segnalazioni=new Segnalazioni();
  users;
  username: string;
  data_alternativa;
  form_p=new Form_Presenze;
  ora_separazione=14
  //ingresso= new Ip_Timbri();
  uscita: boolean;
  access_level;
  note_direttore;
  terapie_eseguite=new Array<any>();
  array_operatori=new Array<any>();
  array_valori=new Array<any>();
  numero_terapie: number;
  array_finale= new Array<any>();
  paziente= new Pazienti_ordine;
  //tipi_cardiologia=['Visita','Prima_Visita_Priority','Visita_priority','Visita_di_controllo','Controllo_con_ECG','ECG','Ecocardiogramma','TSA', 'TestSforzo','DopplerArti','Test_del_Cammino'];
  //tipi_fisiatria=['Visita','VisitaInail','Elettromiografia', 'Baropodometria','Baropodometria_+_T.P.','Biometria','Baropodometria_+_Biometria'];
  tipi_pneumologia= new Array<TipiVisite>();
  tipi_cardiologia= new Array<TipiVisite>();
  tipi_fisiatria= new Array<TipiVisite>();
  //tipi_pneumologia=['Visita','Visita_di_controllo','Visita_privata','Visita_+_Sp.Globale','Visita_+_Sp.Completa', 'Visita_+_Sp.Semplice', 'Visita_+_Sp.Basale_con_Broncodilatazione','Sp.Globale','Sp.Semplice','Sp.Basale_con_Broncodilatazione','Sp.Completa','Controllo_con_Sp.Semplice','Controllo_con_Sp.Globale','Controllo_con_Sp.Completa','Controllo_con_Sp.Basale_con_Broncodilatazione','Prick_Test', 'Test_del_Cammino','Polisonnografia','Monitoraggio_Sat_O2'];
  ordine=new Array();
  intervalID_C;
  intervalID_P;
  intervalID_F;
  intervalID_D;
  ingresso= new Ip_Timbri();
  esitoSave: string;
  terapie_da_eseguire: any;
  box: any;

  displayedColumns: string[] = ['utente', 'terapia'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private service: GlobalServices, private _snackBar: MatSnackBar,public dialog: MatDialog, private router: Router ) { 
    router.events.subscribe( (event: Event) => {

      //if (event instanceof NavigationStart) {}

      if (event instanceof NavigationEnd) {
        if(this.intervalID_C) clearTimeout(this.intervalID_C);
        if(this.intervalID_P) clearTimeout(this.intervalID_P);
        if(this.intervalID_F) clearTimeout(this.intervalID_F);
        if(this.intervalID_D) clearTimeout(this.intervalID_D);
      }

     // if (event instanceof NavigationError) {console.log(event.error);}
      });
   }

  ngOnInit() {
    
    this.box='P';
    this.access_level=this.service.get_access_level();
    if(this.access_level==1) this.CaricaUtenti();
    this.CaricaNote();
    if(this.access_level<4) this.CaricaTerapie();
    this.ordine['Cardiologia']=new Array<Pazienti_ordine>();
    this.ordine['Fisitaria']=new Array<Pazienti_ordine>();
    this.ordine['Pneumologia']=new Array<Pazienti_ordine>();
    if(this.access_level==1 || this.access_level==6 || this.access_level==7 || this.access_level==8)
    {
      this.CaricaOrdine('Cardiologia');
    }

    if(this.access_level==1 || this.access_level==2 || this.access_level==9)
    {
      this.CaricaOrdine('Fisiatria');
    }

    if(this.access_level==1 || this.access_level==2 || this.access_level==10)
    {
      this.CaricaOrdine('Pneumologia');
    }
    if(this.access_level==1 || this.access_level==2)
    {
      this.CaricaOrdine('Direttore');
      this.service.get_function(this.service.apiPolisonno).subscribe(
        result=>{this.array_polisonno=result;}
      )
    }



    if(this.access_level==6 || this.access_level==1){
      this.service.get_function(this.service.apiGetUsername+'/smc').subscribe(
        data=>
        {
          this.users=data;
        }
      )
    }

    this.service.get_function(this.service.apiGetTipiVisite+'4').subscribe(
      (data: Array<TipiVisite>)=> this.tipi_pneumologia=data
    );
    
    this.service.get_function(this.service.apiGetTipiVisite+'2').subscribe(
      (data: Array<TipiVisite>)=> this.tipi_cardiologia=data
    );

    this.service.get_function(this.service.apiGetTipiVisite+'1').subscribe(
      (data: Array<TipiVisite>)=> this.tipi_fisiatria=data
    );

    this.service.get_function(this.service.apiGetPazienti).subscribe(
      data=>{
        this.pazienti=data;
        //this.pazienti.forEach(element=>this.pazienti_concat.push(element.cognome+''))
      }
    );

    this.Verifica();
  }

  


  SetPaziente(valore){
    this.paziente.cognome=valore.cognome;
    this.paziente.nome=valore.nome;
    this.paziente.data_nascita=valore.data_nascita
  }

  Esegui_terapia(segmento,terapia,idscheda,key){
    var obj= new Oggetto_Esegui_terapia;
    var terF= new Terapia_eseguita;
    obj.segmento=segmento;
    obj.terapia=terapia;
    obj.idscheda=idscheda;
    terF.box=obj.box=this.box;
    terF.timestamp=new Date().toString();
    terF.username=this.service.get_username();

    this.service.post_function(JSON.stringify(obj),this.service.apiTerapia).subscribe(
      update=>
      {
        var messaggio='';
        this.esitoSave="Operazione effettuata correttamente";
        this._snackBar.open(this.esitoSave, 'OK', { duration: 2500});
        const index = this.terapie_da_eseguire.indexOf(key, 0);
        if (index > -1) {
          this.terapie_da_eseguire.splice(index, 1);
        }
      }
    )
  }

  applyFilter(evento) {
    const filterValue = (evento.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  get_tipo_visita(tipo,branca,x){
    switch(branca){
      case 'Pneumologia':
        var j=this.tipi_pneumologia.filter(element=>element.tipo_visita==tipo)[0];
        if(j && x==1) return j.descrizione;
        else if(j && x==2) return j.cctrl;
        break;
      case 'Cardiologia':
        var j=this.tipi_cardiologia.filter(element=>element.tipo_visita==tipo)[0];
        if(j && x==1) return j.descrizione;
        else if(j && x==2) return j.cctrl;
        break;
      case 'Fisiatria':
        var j=this.tipi_fisiatria.filter(element=>element.tipo_visita==tipo)[0];
        if(j && x==1) return j.descrizione;
        else if(j && x==2) return j.cctrl;
        break;
    }
    
  }

  StampaCertificato(paziente,tipo,branca){
    this.service.print('Presenza',tipo,new Date(),paziente,'','',branca,1,'');
  }

  EstraiData(valore){
    if(valore.length==16) this.paziente.data_nascita=this.service.get_data_di_nascita(valore);
  }

  InserisciPrestazionePrivata(){
    if(this.privato.paziente && this.privato.data && this.privato.prestazione && this.privato.username) this.service.post_function(JSON.stringify(this.privato),this.service.apiPostPrestazionePrivata).subscribe(
      data=>{
        if(data) {window.alert('Prestazione inserita'); this.privato= new Privato();}
        else window.alert('Operazione non riuscita, ricontrolla i dati o riprova più tardi');
      });
      else window.alert('Riempire tutti i campi!!!')
  }
  
  InserisciSegnalazione(){
   this.service.post_function(JSON.stringify(this.segnalazioni),this.service.apiPostSegnalazioni).subscribe(
      data=>{
        if(data) {window.alert('Segnalazione inviata correttamente'); this.segnalazioni= new Segnalazioni();}
        else window.alert('Operazione non riuscita, ricontrolla i dati o riprova più tardi');
      });
  }

  Verifica(){
    this.service.get_function(this.service.apiGetIngresso+'/'+this.service.get_username()).subscribe(
      (ingresso: any)=>{
        this.ingresso.ingresso=ingresso;
        if(ingresso!='00:00:00') this.uscita=true;
        else this.uscita=false;
      }
    );
  }


  Timbra(){
    this.service.post_function(JSON.stringify(this.ingresso),this.service.apiTimbra+this.username).subscribe(
      status => {
        var messaggio='';
        this.esitoSave="Operazione effettuata correttamente";
        this._snackBar.open(this.esitoSave, 'OK', { duration: 2500});
        this.Verifica();
      }
    );
  }

  
  CheckNota(){
    let flag=0;
    if(this.note_direttore) this.note_direttore.forEach(element => {
      if(element.tipo==this.access_level || element.tipo==0) flag=1;
      });
    return flag;
  }

  MostraNascondi(branca){
    if(this.ora_separazione==14) this.ora_separazione=8;
    else this.ora_separazione=14;
    this.CaricaOrdine(branca);
  }

  CaricaOrdine(branca){
    this.ordine[branca]=new Array<Pazienti_ordine>();
    this.service.get_function(this.service.apiOrdine+branca).subscribe((dati: any)=>{
      if(dati) dati.forEach(element=>{
        let now=new Date().getHours();
        if(now<=this.ora_separazione) 
        {
          if(parseInt(element.ora_accesso.substring(0,2))<=this.ora_separazione)
          this.ordine[branca].push(element);
        }
        else
        {
          if(parseInt(element.ora_accesso.substring(0,2))>=this.ora_separazione)
          this.ordine[branca].push(element);
        }
      })
    })
    switch(branca){
      case 'Pneumologia':
        this.intervalID_P = setTimeout(()=>{this.CaricaOrdine(branca)},180000);
      break;
      case 'Fisiatria':
        this.intervalID_F = setTimeout(()=>{this.CaricaOrdine(branca)},180000);
      break;
      case 'Cardiologia':
        this.intervalID_C = setTimeout(()=>{this.CaricaOrdine(branca)},180000);
      break;
      case 'Direttore':
        this.intervalID_D = setTimeout(()=>{this.CaricaOrdine(branca)},180000);
        break;

    }
  }

  VediPrecedente(){
    this.form_p.data_in=this.data_alternativa;
    this.form_p.data_fin=this.data_alternativa;
    this.service.post_function(JSON.stringify(this.form_p),this.service.apiGetTerapieEseguitePrecedenti).subscribe(
      (data: any)=>{
        this.terapie_eseguite=data;
        this.numero_terapie=data.length;});
  }

  Add(branca){
    if(this.paziente.nome&&this.paziente.cognome&&this.paziente.data_nascita)
    this.service.post_function(JSON.stringify(this.paziente),this.service.apiOrdine+branca+'/'+this.paziente.tipo).subscribe(
      data=>{
        if(data) 
        this.CaricaOrdine(branca);
        this.paziente.nome="";
        this.paziente.cognome="";
        this.paziente.data_nascita="";
        this.service.post_function(JSON.stringify(this.cf.toUpperCase()),this.service.apiPostMetaPazienti+data).subscribe(
          data_bis=> this.cf=""
        );
      }
    )
    else window.alert("Inserire tutti i campi prima del paziente");
  }

  Del(branca,id){
    this.service.get_function(this.service.apiOrdine+'delete/'+id).subscribe(
      data=>{
        if(data=="OK") this.CaricaOrdine(branca);
      }
    )
  }

  routing(id,branca,tipo){
    var priority;priority=this.makeid(3)+tipo+this.makeid(3);
    this.router.navigate(['archivio/visite',id,branca,priority]);
  }

  routing2(id,branca,tipo){
    var priority;
    if(tipo=='Visita_priority') priority=this.makeid(3)+'1'+this.makeid(3);
    else if(tipo=='Visita_di_controllo') priority=this.makeid(3)+'2'+this.makeid(3);
    else priority=this.makeid(7);
    return 'archivio/visite/'+id+'/'+branca+'/'+priority;
  }

  makeid(j) {
    var text = ""; 
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZjkmnopqrstuvwxyz";

  
    for (var i = 0; i < j; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  CaricaUtenti(){
    this.service.get_function(this.service.apiGetUsername+'/now').subscribe(
      (data: any) =>this.utenti=data
    )
  }

  CaricaNote(){
    this.service.get_function(this.service.apiGetNoteDirettore).subscribe(
      data=>this.note_direttore=data
    )
  }
  get_tipo(obj){
    let temp="";
    switch(parseInt(obj.tipo)){
      case 0: temp="Tutti"; break;
      case 1: temp="Amministratori"; break;
      case 2: temp="Segreteria M2 e CS"; break;
      case 3: temp="Terapista"; break;
      case 4: temp="Collaboratore"; break;
      case 6: temp="Segreteria CDCP"; break;
      case 7: temp="Infermiera CDCP"; break;
      case 8: temp="Cardiologo"; break;
      case 9: temp="Fisiatra"; break;
      case 10: temp="Pneumologo"; break;
      case 11: temp="Collaboratore Polisonnografie"; break;
    }
    return temp;
  }

  CaricaTerapie(){
    this.service.get_function(this.service.apiGetTerapieOggi).subscribe(
      (data: any)=>{
        this.terapie_eseguite=data;
        this.numero_terapie=data.length;
        
        this.service.get_function(this.service.apiGetUsername+'/sttr').subscribe(
          (data: any)=>
          {
            this.array_operatori=data;
            this.Statistiche();
          }
        )
      }
    )

    this.service.get_function(this.service.apiGetTerapiedaEseguire).subscribe(
      (data:any)=>{
        this.terapie_da_eseguire=data;
        for(let i=0; i<data.length;i++) this.terapie_da_eseguire[i].scheda=JSON.parse(this.terapie_da_eseguire[i].scheda);
        this.dataSource = new MatTableDataSource(this.terapie_da_eseguire);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    )
  }

  Statistiche(){
    this.array_valori=new Array<any>();
    this.array_operatori.forEach(element => {
      this.array_valori.push({'name': element.username,'value':0});
    });
    this.terapie_eseguite.forEach(terapia=>{
      for(let i=0;i<this.array_valori.length;i++){
        if(this.array_valori[i].name==terapia.username) this.array_valori[i].value++
      }
    });
  }

  ModificaNota(id){
    //this.router.navigate(["/note-direttore",id]);
    const dialogRef = this.dialog.open(NoteDirettoreComponent, {
      width: '850px',
      data:{data: '', id: id},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.CaricaNote()
    });

  }

  RefreshPaziente(el){
    this.paziente.cognome=el.cognome;
    this.paziente.nome=el.nome;
    this.paziente.data_nascita=el.data_nascita;
  }

  OpenDialog(paziente){
    const dialogRef = this.dialog.open(ElencoPazientiComponent, {
      maxWidth: '1000px',
      maxHeight: '700px',
      data:{id: paziente.idscheda},
    });

   /*dialogRef.afterClosed().subscribe(result => {
      this.CaricaNote()
    });*/
  }

  ArchiviaNota(id){
    this.service.post_function(JSON.stringify(id),this.service.apiDeleteNota+id).subscribe(
      data=>{if(data) this.CaricaNote()}
    )
  }

  Nuovanota(){
    //this.router.navigate(["/note-direttore"]);
    const dialogRef = this.dialog.open(NoteDirettoreComponent, {
      width: '850px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.CaricaNote()
    });
  }

  Refresh(val){
    switch(val){ 
      case 0: this.CaricaTerapie();
              break;
      case 1: this.CaricaUtenti();
              break;
    }
  }


}




