<mat-card>
    <mat-card-title>{{array_polisonno.length}} Polisonnografie trovate</mat-card-title>
    <mat-card-content class="push-bottom-none">
      <div flexLayout="row" id="elenco">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="15%">
          <mat-form-field>
              <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="temp.data_in">
            </mat-form-field>
        </div>
        <div fxFlex="15%">
            <mat-form-field>
                <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="temp.data_out">
            </mat-form-field>
        </div>
        <div fxFlex="10%">
            <button mat-button color="primary" (click)="Cerca()">FILTRA</button>
        </div>
        <div fxFlex="60%"></div>
      </div>
      <div fxLayout="row" fx-layout-align="start center">
        <mat-form-field appearance="standard">
            <mat-label>Filtra i pazienti</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Scrivi qui il cognome..." #input>
          </mat-form-field>
      </div>
          <mat-divider></mat-divider>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
              
            <!-- Cognome Column -->
            <ng-container matColumnDef="cognome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
              <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
            </ng-container>
          
            <!-- Nome Column -->
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>
          
            <!-- Data di Nascita Column -->
            <ng-container matColumnDef="data_nascita">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data di Nascita </th>
              <td mat-cell *matCellDef="let element"> {{element.data_nascita | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Data Richiesta Column -->
            <ng-container matColumnDef="data_richiesta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Richiesta </th>
                <td mat-cell *matCellDef="let element"> {{element.data_richiesta | date:'dd/MM/yyyy'}} </td>
              </ng-container>

              <!-- Telefono Column -->
            <ng-container matColumnDef="telefono">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
              </ng-container>

              <!-- Indirizzo Column -->
            <ng-container matColumnDef="indirizzo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Indirizzo </th>
                <td mat-cell *matCellDef="let element"> {{element.via}} - {{element.citta}} </td>
              </ng-container>

              <!-- Note Column -->
            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Note </th>
              <td mat-cell *matCellDef="let element"> {{element.note}}</td>
            </ng-container>

              <!-- Data Esecuzione Column -->
            <ng-container matColumnDef="data_esecuzione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Esecuzione </th>
                <td mat-cell *matCellDef="let element"> <span *ngIf="!element.data_esecuzione"><mat-icon style="color: yellow">change_history</mat-icon></span>
                    <span *ngIf="element.data_esecuzione"><mat-icon style="color: green; vertical-align: middle;">check_circle</mat-icon> {{element.data_esecuzione | date: 'dd/MM/yyyy'}} </span> </td>
              </ng-container>

              <!-- Data Refertazione Column -->
            <ng-container matColumnDef="data_refertazione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Refertazione </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="!element.data_refertazione"><mat-icon style="color: yellow">change_history</mat-icon></span>
                <span *ngIf="element.data_refertazione"><mat-icon style="color: green; vertical-align: middle;">event_note</mat-icon> {{element.data_refertazione | date: 'dd/MM/yyyy'}} </span>
                </td>
              </ng-container>

              <!-- Data Consegna Column -->
              <ng-container matColumnDef="data_consegna">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Consegna </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="!element.data_consegna"><mat-icon style="color: yellow">change_history</mat-icon></span>
                <span *ngIf="element.data_consegna"><mat-icon style="color: green; vertical-align: middle;">contact_mail</mat-icon> {{element.data_consegna | date: 'dd/MM/yyyy'}} </span>
                </td>
              </ng-container>

            <!-- Azioni -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [mat-menu-trigger-for]="menu">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu x-position="before" #menu="matMenu">
                        <button *ngIf="access_level==1" (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina</button>
                        <button *ngIf="access_level==1 || access_level==11" (click)="Modificato('e',element)" mat-menu-item><mat-icon mat-list-icon style="color: green">check_circle</mat-icon>Segna Eseguito</button>
                        <button *ngIf="access_level==1" (click)="Modificato('r',element)" mat-menu-item><mat-icon mat-list-icon style="color: green">event_note</mat-icon>Segna Refertato</button>
                        <button *ngIf="access_level==1" (click)="Modificato('c',element)" mat-menu-item><mat-icon mat-list-icon style="color: green">contact_mail</mat-icon>Segna Consegnato</button>
                        <button *ngIf="access_level==1 || access_level==11" (click)="openDialog(element)" mat-menu-item><mat-icon mat-list-icon style="color: green">edit</mat-icon>Aggiungi Nota</button>
                    </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                  class="example-element-row">
              </tr>
            
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>

      <div flexLayout="row">
          <h3>Inserisci nuova richiesta</h3>
          <div fxLayout="row" fxLayout.xs='column' fxLayoutGap="5px">
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                          <input matInput placeholder="Cognome" [(ngModel)]="polisonno.cognome" name="polisonno.cognome">
                  </mat-form-field>
              </div>
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                          <input matInput placeholder="Nome" [(ngModel)]="polisonno.nome" name="polisonno.nome">
                  </mat-form-field>
              </div>
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                      <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="polisonno.data_nascita">
                  </mat-form-field>
              </div>
              <div fxFlex="10%">
                  <mat-form-field fxFlexFill>
                    <input matInput placeholder="Telefono" [(ngModel)]="polisonno.telefono" name="polisonno.telefono">
                  </mat-form-field>
              </div>
              <div fxFlex="20%">
                  <mat-form-field fxFlexFill>
                    <input matInput placeholder="Città" [(ngModel)]="polisonno.citta" name="polisonno.citta">
                  </mat-form-field>
              </div>
              <div fxFlex="25%">
                <mat-form-field fxFlexFill>
                  <input matInput placeholder="Via" [(ngModel)]="polisonno.via" name="polisonno.via">
                </mat-form-field>
              </div>
              
          </div>
          <div fxLayout="row" fxLayout.xs='column' fxLayoutGap="5px">
            <div fxFlex="80%">
              <mat-form-field fxFlexFill>
                <input matInput placeholder="Note" [(ngModel)]="polisonno.note" name="polisonno.note">
              </mat-form-field>
            </div>
            <div fxFlex="5%">
              <button mat-raised-button color="primary" (click)="Aggiungi()">Aggiungi</button>
          </div>
          </div>
      </div>
    </div>
    </mat-card-content>
  </mat-card>
