import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import {Pazienti, PRPN, Scheda, Terapia, Scheda_Visita, Impostazioni, Anamnesi,VisitaC,EcoD,ECG_SFORZO, BPE, VP, pricktest, Test_Cammino, Oggetto_Esegui_terapia_forzata, Oggetto_Esegui_terapia, Oggetto_Rimuovi_terapia, Terapia_eseguita} from '../../_models';
import {GlobalServices} from '../../_services';
import {  Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { leadingComment } from '@angular/compiler';
import { te } from 'date-fns/locale';

@Component({
  selector: 'app-elenco-pazienti',
  templateUrl: './elenco-pazienti.component.html',
  styleUrls: ['./elenco-pazienti.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      //transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ElencoPazientiComponent implements OnInit {

  //Elenco variabili schede
  dati_schede= new Array<Scheda>();
  box_selected;
  username_forzato;
  timestamp_forzato;
  utenti=new Array<any>();
  quantita_abilitata='1';
  option_select=["-1","-2","-3","-4","-5","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"];


  //Elenco variabili globali

  elenco_pazienti= new Array<Pazienti>();
  access_level;
  filteredData: any[] = this.elenco_pazienti;
  filteredTotal: number = this.elenco_pazienti.length;
  selectedRows: any[] = [];
  public esitoSave: string;
  dataSource: MatTableDataSource<Pazienti>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  impostazioni_cdcp= new Impostazioni;
  impostazioni_cs= new Impostazioni;
  impostazioni_m2= new Impostazioni;
  nominativo: string;
  expandedElement: Pazienti | null;
  displayedColumns: string[] = ['id', 'cognome', 'nome', 'data'];
  paziente_selezionato= new Pazienti();
  api;

  //Elenco Variabili Visite
  tipo_visita;
  scheda= new Scheda_Visita();
  visitaC= new VisitaC;
  testC=new Test_Cammino();
  esitoinail;
  eco=new EcoD;
  bpe= new BPE;
  testsforzo=new ECG_SFORZO;
  tsa: string;
  doppler_arti: string;
  visite: Array<any>;
  anamnesi_selezionata= new Anamnesi;  
  data_selezionata;
  vp= new VP;
  prpn = new PRPN;
  prick= new pricktest;
  array_branche;
  dataRef: number;
  filterValue;
  refertazione_privata: any;



  constructor(
    private service: GlobalServices, 
    //private _dataTableService: TdDataTableService,
    private router: Router,
    private notifiche_bottom: MatSnackBar,
    private route: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<ElencoPazientiComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {id: number}
  ) { 
    if(data && data.id) this.dataRef=data.id;
  }
  

  ngOnInit() {
    //fUNZIONI INIZIALI PER SCHEDE
    this.box_selected="P";
    this.access_level=this.service.get_access_level();
    if(this.access_level==1) this.service.get_function(this.service.apiGetUtenti+'/terapisti').subscribe(
      data=>{this.utenti=data; this.username_forzato=this.utenti[0].username;}
    )
    
    this.service.get_function(this.service.apiGetImpostazioni+'cs').subscribe(data=>this.impostazioni_cs=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'m2').subscribe(data=>this.impostazioni_m2=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'cdcp').subscribe(data=>this.impostazioni_cdcp=JSON.parse(data[0].value));
    this.access_level=this.service.get_access_level();
    switch(this.access_level){
      case 6:
      case 7:
      case 8: this.tipo_visita='Cardiologia'; break;
      case 9: this.tipo_visita='Fisiatria'; break;
      case 10: this.tipo_visita='Pneumologia'; break;
    }

    if(this.access_level!=3) this.api=this.service.apiGetPazienti;
    else this.api=this.service.apiGetPazientiSchede+'/all';
    this.service.get_function(this.api).subscribe(
      response => {
        this.elenco_pazienti=response;
        this.dataSource=new MatTableDataSource(this.elenco_pazienti);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if(this.dataRef)
        {
              let temp=this.elenco_pazienti[this.elenco_pazienti.findIndex(el=>el.idscheda==this.dataRef)];
              this.elenco_pazienti=new Array<Pazienti>();
              this.elenco_pazienti.push(temp);
              this.dataSource=new MatTableDataSource(this.elenco_pazienti);
        }
        /*this.route.params.forEach(params => {
          this.paziente_selezionato.idscheda=params['id'];
          if( this.paziente_selezionato.idscheda) {
            
            if(this.elenco_pazienti.findIndex(el=>el.idscheda==this.paziente_selezionato.idscheda)) 
            {
              let temp=this.elenco_pazienti[this.elenco_pazienti.findIndex(el=>el.idscheda==this.paziente_selezionato.idscheda)];
              this.elenco_pazienti=new Array<Pazienti>();
              this.elenco_pazienti.push(temp);
              this.dataSource=new MatTableDataSource(this.elenco_pazienti);
            }
              //let multipleFilter= {};
              //multipleFilter['cognome']=this.elenco_pazienti[this.elenco_pazienti.findIndex(el=>el.idscheda==this.paziente_selezionato.idscheda)].cognome.trim().toLowerCase();
              //multipleFilter['nome']=this.elenco_pazienti[this.elenco_pazienti.findIndex(el=>el.idscheda==this.paziente_selezionato.idscheda)].nome.trim().toLowerCase();
           // this.dataSource.filter = JSON.stringify(multipleFilter);}//this.elenco_pazienti[this.elenco_pazienti.findIndex(el=>el.idscheda==this.paziente_selezionato.idscheda)].cognome.trim().toLowerCase();}
          }
        });*/
      }
    );

    

    
    
  }

  StampaCertificato(paziente,tipo,branca){
    this.service.print('Presenza_FKT',tipo,new Date(),paziente,'','',branca,1,'');
  }

  CaricaPaziente(){
    this.service.get_function(this.service.apiGetPazienti+this.paziente_selezionato.idscheda).subscribe(data=>{
      this.paziente_selezionato=data[0];
      
      setTimeout(()=>{
        this.dataSource.filter = this.paziente_selezionato.cognome.trim().toLowerCase();
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }},500);
    });
  }

  applyFilter(type,event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = this.filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  DisattivaPaziente(id){
    this.service.get_function(this.service.apiDeletePaziente+id).subscribe(data=>{
      if(data)this.service.get_function(this.service.apiGetPazienti).subscribe(
        response => {
          this.paziente_selezionato= new Pazienti();
          this.elenco_pazienti=response;
          //this.filter();
        }
      );
    });
  }


  MostraVisite(riga){
    this.expandedElement = this.expandedElement === riga ? null : riga;

    if(this.expandedElement)
    {
      this.paziente_selezionato=riga;
      this.visite= new Array<any>();
      this.Carica_Visite(riga.idscheda);
      //schede
      if(this.access_level<4 || this.access_level==10) this.CaricaSchede(riga);
    }

    
  }

  CaricaSchede(riga){
    this.dati_schede= new Array<Scheda>();
    if(this.access_level<3)this.service.get_function(this.service.apiGetSchede+riga.idscheda).subscribe(dati=>{if(dati)this.dati_schede=dati});
    else this.service.get_function(this.service.apiGetSchede+riga.idscheda+'/attivo').subscribe(dati=>{if(dati)this.dati_schede=dati});
  }


  Carica_Visite(id){
    this.service.get_function(this.service.apiGetVisite+id).subscribe(
      dati=>
      {
        if(dati) this.visite=dati
      });
  }

  showInfo(obj,stampa)
  {
    this.data_selezionata=obj.data;
    this.service.get_function(this.service.apiGetAnamnesi+obj.branca+'/'+obj.data+'/'+this.paziente_selezionato.idscheda).subscribe(data=>
      {
        if(data) this.anamnesi_selezionata=JSON.parse(data[0].valore);
        this.service.get_function(this.service.apiGetNominativo+obj.operatore).subscribe(
          dato=>
          {
            var tipo;
            switch(obj.tipo){
              case 'VisitaControllo':
              case 'VisitaPriority':
              case 'PrimaVisitaPriority':
              case 'ControlloConEcg':
              case 'ECG':
              case 'Visita':
                              tipo="visitaC";
                              this.visitaC= new VisitaC;
                              this.visitaC=JSON.parse(obj.valore);
                              break;
              case 'EcocardiogrammaColorDoppler':
                              tipo='EcoD';
                              this.eco= new EcoD
                              this.eco=JSON.parse(obj.valore);
                              break;
              case 'TSA':
                              tipo='TSA';
                              this.tsa= JSON.parse(obj.valore);
                              break;
              case 'DopplerArti':
                              tipo='DOPPLER';
                              this.doppler_arti=JSON.parse(obj.valore);
                              break;
              case 'refertazione_privata':
                              tipo='refertazione_privata';
                              this.refertazione_privata=JSON.parse(obj.valore);
                              break;
              case 'PRI':
                              tipo='PRI';
                              this.scheda= new Scheda_Visita;
                              this.scheda=JSON.parse(obj.valore);
                              break;
              case 'TestSforzo':
                              tipo='TestSforzo';
                              this.testsforzo= new ECG_SFORZO;
                              this.testsforzo=JSON.parse(obj.valore);
                              break;
              case 'BPE':
                              tipo='BPE';
                              this.bpe= new BPE;
                              this.bpe=JSON.parse(obj.valore);
                              break;
              case 'EsitoInail':
                              tipo='EsitoInail';
                              this.esitoinail=JSON.parse(obj.valore);
                              break;
              case 'Visita_di_controllo':
              case 'Visita_privata':
              case 'Visita_+_Sp.Globale':
              case 'Visita_+_Sp.Completa': 
              case 'Visita_+_Sp.Semplice':
              case 'Visita_+_Sp.Basale_con_Broncodilatazione':
              case 'Sp.Globale':
              case 'Sp.Semplice':
              case 'Sp.Basale_con_Broncodilatazione':
              case 'Sp.Completa':
              case 'Controllo_con_Sp.Semplice':
              case 'Controllo_con_Sp.Globale':
              case 'Controllo_con_Sp.Completa':
              case 'Controllo_con_Sp.Basale_con_Broncodilatazione':
              case 'Polisonnografia':
              case 'Sp.Semplice_e_Broncodilatazione':
              case 'Sp.Globale_e_Broncodilatazione':
              case 'VisitaPneumo':
                              tipo='VisitaPneumo';
                              this.vp=new VP;
                              this.vp=JSON.parse(obj.valore);
                              break;
              case 'PRPN':
                              tipo='PRPN';
                              this.prpn=new PRPN;
                              this.prpn=JSON.parse(obj.valore);
                              break;
              case 'PrickTest':
                              tipo='PrickTest';
                              this.prick=new pricktest;
                              this.prick=JSON.parse(obj.valore);
                              break;
              case 'TestCamminoCDCP':
              case 'TestCammino':
                              tipo='TestCammino';
                              this.testC=new Test_Cammino;
                              this.testC=JSON.parse(obj.valore);
                              break;
            }
            this.nominativo=dato[0].nominativo;
            let imp;
            switch(obj.branca){
              case 'Fisiatria':
              imp=this.impostazioni_cs;
              break;
              case 'Pneumologia':
              imp=this.impostazioni_m2;
              break;
              case 'Cardiologia':
              imp=this.impostazioni_cdcp;
              break;
            }
           
           this.service.print(tipo,JSON.parse(obj.valore),this.data_selezionata,this.paziente_selezionato,this.nominativo,this.anamnesi_selezionata,obj.branca,stampa,imp);
          }
        )
      }
      )
    
  }

  routing(nav,id){
    this.router.navigate([nav,id]);
  }

  routing_visite(nav,id,dir){
    this.router.navigate([nav,id,dir]);
  }

  //FUNZIONI PER SCHEDE

  Archivia(id){
    this.service.get_function(this.service.apiGetSchede+'attiva/'+id).subscribe(dati=>{
      var i=0;
      this.dati_schede.forEach(element=>{
        if(element.id_scheda==id) this.dati_schede[i].attivo= this.dati_schede[i].attivo===1 ? 0 : 1;
        i++
      })
    });
    }

  Rimuovi_operazione(segmento,terapia,id_scheda,timestamp){
    var obj=new Oggetto_Rimuovi_terapia;
    obj.segmento=segmento;
    obj.terapia=terapia;
    obj.idscheda=id_scheda;
    obj.timestamp=timestamp;
    this.service.post_function(JSON.stringify(obj),this.service.apiDeleteTerapia).subscribe(
      update=>this.CaricaSchede(this.paziente_selezionato)
    )
  }

  check_data(scheda: Scheda, terapia:Terapia){
    var valore: boolean
    scheda.terapie.forEach(terapy=>{
      if(terapy.id_terapia==terapia.id_terapia && terapy.id_segmento==terapia.id_segmento){
        if(terapy.eseguite){
          var data_=new Date(terapy.eseguite[terapy.eseguite.length-1].timestamp);
          var now= new Date();
          if(data_.getDate()==now.getDate()&&data_.getMonth()==now.getMonth()&&data_.getFullYear()==now.getFullYear()) valore=false;
          else valore=true;
        }
        else {
          valore=true;}
      }
    });
    return valore;
  }

  Esegui_terapia(segmento,terapia,idscheda){
    var obj= new Oggetto_Esegui_terapia;
    var terF= new Terapia_eseguita;
    obj.segmento=segmento;
    obj.terapia=terapia;
    obj.idscheda=idscheda;
    terF.box=obj.box=this.box_selected;
    terF.timestamp=new Date().toString();
    terF.username=this.service.get_username();

    this.service.post_function(JSON.stringify(obj),this.service.apiTerapia).subscribe(
      update=>
      {
        var i=0;
      this.dati_schede.forEach(element=>{
        if(element.id_scheda==idscheda) {
          var j=0;
          this.dati_schede[i].terapie.forEach(ter=>{
            if(ter.id_terapia==terapia && ter.id_segmento==segmento) {
              if(!this.dati_schede[i].terapie[j].eseguite) this.dati_schede[i].terapie[j].eseguite= new Array<Terapia_eseguita>();
              this.dati_schede[i].terapie[j].eseguite.push(terF);
              this.dati_schede[i].terapie[j].quantita_eseguite=parseInt(this.dati_schede[i].terapie[j].quantita_eseguite)+1;
            }
            j++
          })
        }
        i++
      })
        //this.CaricaSchede(this.paziente_selezionato)
      }
    )
  }

  Esegui_terapia_forzata(segmento,terapia,idscheda){
    var obj= new Oggetto_Esegui_terapia_forzata;
    var terF= new Terapia_eseguita;
    obj.segmento=segmento;
    obj.terapia=terapia;
    obj.idscheda=idscheda;
    terF.box=obj.box=this.box_selected;
    terF.username=obj.username=this.username_forzato;
    terF.timestamp=obj.timestamp=this.timestamp_forzato;

    this.service.post_function(JSON.stringify(obj),this.service.apiTerapiaForzata).subscribe(
      update=> {
        var i=0;
      this.dati_schede.forEach(element=>{
        if(element.id_scheda==idscheda) {
          var j=0;
          this.dati_schede[i].terapie.forEach(ter=>{
            if(ter.id_terapia==terapia && ter.id_segmento==segmento) {
              if(!this.dati_schede[i].terapie[j].eseguite) this.dati_schede[i].terapie[j].eseguite= new Array<Terapia_eseguita>();
              this.dati_schede[i].terapie[j].eseguite.push(terF);
              this.dati_schede[i].terapie[j].quantita_eseguite=parseInt(this.dati_schede[i].terapie[j].quantita_eseguite)+1;
            }
            j++
          })
        }
        i++
      })
        //this.CaricaSchede(this.paziente_selezionato)
      }
    )
  }

  Abilita_terapia(segmento,terapia,idscheda){
    var obj= new Oggetto_Esegui_terapia;
    obj.segmento=segmento;
    obj.terapia=terapia;
    obj.idscheda=idscheda;
    obj.box=this.quantita_abilitata;

    this.service.post_function(JSON.stringify(obj),this.service.apiAbilitaTerapia).subscribe(
      update=>
      {
        var i=0;
      this.dati_schede.forEach(element=>{
        if(element.id_scheda==idscheda) {
          var j=0;
          this.dati_schede[i].terapie.forEach(ter=>{
            if(ter.id_terapia==terapia && ter.id_segmento==segmento) this.dati_schede[i].terapie[j].quantita_da_eseguire=parseInt(this.dati_schede[i].terapie[j].quantita_da_eseguire)+parseInt(this.quantita_abilitata);
            j++
          })
        }
        i++
      })
        //this.CaricaSchede(this.paziente_selezionato)
      }
    )
  }

}
