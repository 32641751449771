<mat-card>
    <mat-card-title>{{array_plantari.length}} Plantari trovati</mat-card-title>
    <mat-card-content class="push-bottom-none">
      <div flexLayout="row" id="elenco">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="15%">
          <mat-form-field>
              <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="temp.data_in">
            </mat-form-field>
        </div>
        <div fxFlex="15%">
            <mat-form-field>
                <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="temp.data_out">
            </mat-form-field>
        </div>
        <div fxFlex="10%">
            <button mat-button color="primary" (click)="Cerca()">FILTRA</button>
        </div>
        <div fxFlex="60%"></div>
      </div>
      <div fxLayout="row" fx-layout-align="start center">
        <mat-form-field appearance="standard">
            <mat-label>Filtra i pazienti</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Scrivi qui il cognome..." #input>
          </mat-form-field>
      </div>
          <mat-divider></mat-divider>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
              
            <!-- Cognome Column -->
            <ng-container matColumnDef="cognome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
              <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
            </ng-container>
          
            <!-- Nome Column -->
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>
          
            <!-- Data di Nascita Column -->
            <ng-container matColumnDef="data_nascita">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data di Nascita </th>
              <td mat-cell *matCellDef="let element"> {{element.data_nascita | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Data Richiesta Column -->
            <ng-container matColumnDef="data_richiesta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Richiesta </th>
                <td mat-cell *matCellDef="let element"> {{element.data_richiesta | date:'dd/MM/yyyy'}} </td>
              </ng-container>

              <!-- Telefono Column -->
            <ng-container matColumnDef="telefono">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
              </ng-container>

              <!-- Scarpa Column -->
            <ng-container matColumnDef="scarpa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Scarpa </th>
                <td mat-cell *matCellDef="let element"> {{element.scarpa}} </td>
              </ng-container>

              <!-- Acconto Column -->
            <ng-container matColumnDef="acconto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acconto </th>
                <td mat-cell *matCellDef="let element"> € {{element.acconto}} </td>
              </ng-container>

              <!-- Spedito Column -->
            <ng-container matColumnDef="spedito">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Spedito </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.spedito==0"><mat-icon style="color: yellow">change_history</mat-icon></span>
                <span *ngIf="element.spedito==1"><mat-icon style="color: green; vertical-align: middle;">check_circle</mat-icon> {{element.data_spedizione | date: 'dd/MM/yyyy'}} </span>
                </td>
              </ng-container>

            <!-- Azioni -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [mat-menu-trigger-for]="menu">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu x-position="before" #menu="matMenu">
                        <button *ngIf="access_level==1" (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina</button>
                        <button *ngIf="access_level==1" (click)="Spedito(element)" mat-menu-item><mat-icon mat-list-icon style="color: green">check_circle</mat-icon>Segna Spedito</button>
                        <button (click)="service.print('Plantare','',today,element,'','','Fisiatria',1,cs)" mat-menu-item><mat-icon mat-list-icon>refresh</mat-icon>Ristampa</button>
                    </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                  class="example-element-row">
              </tr>
            
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
  <!--        <td-data-table
            #dataTable
            [data]="filteredData"
            [columns]="columns"
            [sortable]="true"
            [sortBy]="sortBy"
            [sortOrder]="sortOrder"
            (sortChange)="sort($event)"
            >
  
            <ng-template tdDataTableTemplate="data_richiesta" let-value="data_richiesta" let-row="row">
                {{row.data_richiesta | date: 'dd/MM/yyyy'}}
            </ng-template>
            <ng-template tdDataTableTemplate="data_nascita" let-value="data_nascita" let-row="row">
                {{row.data_nascita | date: 'dd/MM/yyyy'}}
            </ng-template>
            <ng-template tdDataTableTemplate="data_spedizione" let-value="data_spedizione" let-row="row">
                {{row.data_spedizione | date: 'dd/MM/yyyy'}}
            </ng-template>
            <ng-template tdDataTableTemplate="spedito" let-value="spedito" let-row="row">
                <span *ngIf="row.spedito==0"><mat-icon style="color: yellow">change_history</mat-icon></span>
                <span *ngIf="row.spedito==1"><mat-icon style="color: green">check_circle</mat-icon></span>
            </ng-template>
            <ng-template tdDataTableTemplate="data_richiesta" let-value="data_richiesta" let-row="row">
                <span *ngIf="row.data_richiesta">{{row.data_richiesta | date: 'dd/MM/yyyy'}}</span>
            </ng-template>
            <ng-template tdDataTableTemplate="data_spedizione" let-value="data_spedizione" let-row="row">
                <span *ngIf="row.spedito==1">{{row.data_spedizione | date: 'dd/MM/yyyy'}}</span>
            </ng-template>
            <ng-template tdDataTableTemplate="actions" let-value="id" let-row="row">
                <button mat-icon-button [mat-menu-trigger-for]="menu">
                <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu x-position="before" #menu="matMenu">
                    <button *ngIf="access_level==1" (click)="Elimina(row)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina</button>
                    <button *ngIf="access_level==1" (click)="Spedito(row)" mat-menu-item><mat-icon mat-list-icon style="color: green">check_circle</mat-icon>Segna Spedito</button>
                    <button (click)="service.print('Plantare','',today,row,'','','Fisiatria',1,cs)" mat-menu-item><mat-icon mat-list-icon>refresh</mat-icon>Ristampa</button>
                </mat-menu>
            </ng-template>
  
          </td-data-table>
          <div class="mat-padding" *ngIf="!dataTable.hasData" fxLayout="row" layout-align="center center">
              <h3>Nessun risultato da mostrare.</h3>
          </div>
          <div fxLayout="row">
              <td-paging-bar #pagingBar [pageSize]="pageSize" [total]="filteredTotal" (change)="page($event)">
              <div fxLayout="row">
                  <div fxFlex="33%" fxHide.xs='true'>
                      Risultati per pagina:
                  </div>
                  <div fxFlex="33%" fxHide.xs='true'>
                      <mat-select [style.width.px]="50" [(ngModel)]="pageSize">
                      <mat-option *ngFor="let size of [8,16,40,100]" [value]="size">
                          {{size}}
                      </mat-option>
                      </mat-select>
                  </div>
                  <div fxFlex="34%" fxFlex.xs="100%">
                      {{pagingBar.range}} <span hide-xs>di {{pagingBar.total}}</span>
                  </div>
              </div>
              </td-paging-bar>
        </div>
      </div>-->
      <div flexLayout="row">
          <h3>Inserisci nuovo</h3>
          <div fxLayout="row" fxLayout.xs='column' fxLayoutGap="5px">
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                          <input matInput placeholder="Cognome" [(ngModel)]="plantare.cognome" name="plantare.cognome">
                  </mat-form-field>
              </div>
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                          <input matInput placeholder="Nome" [(ngModel)]="plantare.nome" name="plantare.nome">
                  </mat-form-field>
              </div>
              <div fxFlex="15%">
                  <mat-form-field fxFlexFill>
                      <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="plantare.data_nascita">
                  </mat-form-field>
              </div>
              <div fxFlex="10%">
                  <mat-form-field fxFlexFill>
                    <input matInput placeholder="Telefono" [(ngModel)]="plantare.telefono" name="plantare.telefono">
                  </mat-form-field>
              </div>
              <div fxFlex="5%">
                  <mat-form-field fxFlexFill>
                    <input matInput placeholder="Scarpa" [(ngModel)]="plantare.scarpa" name="plantare.scarpa">
                  </mat-form-field>
              </div>
              <div fxFlex="5%">
                <mat-form-field fxFlexFill>
                    <input matInput placeholder="Acconto" [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="plantare.acconto" name="plantare.acconto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option [value]="50">50</mat-option>
                        <mat-option [value]="80">80</mat-option>
                        <mat-option [value]="100">100</mat-option>
                        <mat-option [value]="187.2">187.2</mat-option>
                      </mat-autocomplete>
                </mat-form-field>
              </div>
              <div fxFlex="5%">
                  <button mat-raised-button color="primary" (click)="Aggiungi()">Aggiungi</button>
              </div>
          </div>
      </div>
    </div>
    </mat-card-content>
  </mat-card>